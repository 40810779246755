@-moz-keyframes shake {
  59% {
    margin-left: 0;
  }
  60%, 80% {
    margin-left: 2px;
  }
  70%, 90% {
    margin-left: -2px;
  }
}
@-webkit-keyframes shake {
  59% {
    margin-left: 0;
  }
  60%, 80% {
    margin-left: 2px;
  }
  70%, 90% {
    margin-left: -2px;
  }
}
@-o-keyframes shake {
  59% {
    margin-left: 0;
  }
  60%, 80% {
    margin-left: 2px;
  }
  70%, 90% {
    margin-left: -2px;
  }
}
@keyframes shake {
  59% {
    margin-left: 0;
  }
  60%, 80% {
    margin-left: 2px;
  }
  70%, 90% {
    margin-left: -2px;
  }
}
.black {
  background-color: #000 !important;
  border-color: #000 !important;
}
.black--text {
  color: #000 !important;
  caret-color: #000 !important;
}
.white {
  background-color: #fff !important;
  border-color: #fff !important;
}
.white--text {
  color: #fff !important;
  caret-color: #fff !important;
}
.transparent {
  background-color: transparent !important;
  border-color: transparent !important;
}
.transparent--text {
  color: transparent !important;
  caret-color: transparent !important;
}
.red {
  background-color: #f44336 !important;
  border-color: #f44336 !important;
}
.red--text {
  color: #f44336 !important;
  caret-color: #f44336 !important;
}
.red.lighten-5 {
  background-color: #ffebee !important;
  border-color: #ffebee !important;
}
.red--text.text--lighten-5 {
  color: #ffebee !important;
  caret-color: #ffebee !important;
}
.red.lighten-4 {
  background-color: #ffcdd2 !important;
  border-color: #ffcdd2 !important;
}
.red--text.text--lighten-4 {
  color: #ffcdd2 !important;
  caret-color: #ffcdd2 !important;
}
.red.lighten-3 {
  background-color: #ef9a9a !important;
  border-color: #ef9a9a !important;
}
.red--text.text--lighten-3 {
  color: #ef9a9a !important;
  caret-color: #ef9a9a !important;
}
.red.lighten-2 {
  background-color: #e57373 !important;
  border-color: #e57373 !important;
}
.red--text.text--lighten-2 {
  color: #e57373 !important;
  caret-color: #e57373 !important;
}
.red.lighten-1 {
  background-color: #ef5350 !important;
  border-color: #ef5350 !important;
}
.red--text.text--lighten-1 {
  color: #ef5350 !important;
  caret-color: #ef5350 !important;
}
.red.darken-1 {
  background-color: #e53935 !important;
  border-color: #e53935 !important;
}
.red--text.text--darken-1 {
  color: #e53935 !important;
  caret-color: #e53935 !important;
}
.red.darken-2 {
  background-color: #d32f2f !important;
  border-color: #d32f2f !important;
}
.red--text.text--darken-2 {
  color: #d32f2f !important;
  caret-color: #d32f2f !important;
}
.red.darken-3 {
  background-color: #c62828 !important;
  border-color: #c62828 !important;
}
.red--text.text--darken-3 {
  color: #c62828 !important;
  caret-color: #c62828 !important;
}
.red.darken-4 {
  background-color: #b71c1c !important;
  border-color: #b71c1c !important;
}
.red--text.text--darken-4 {
  color: #b71c1c !important;
  caret-color: #b71c1c !important;
}
.red.accent-1 {
  background-color: #ff8a80 !important;
  border-color: #ff8a80 !important;
}
.red--text.text--accent-1 {
  color: #ff8a80 !important;
  caret-color: #ff8a80 !important;
}
.red.accent-2 {
  background-color: #ff5252 !important;
  border-color: #ff5252 !important;
}
.red--text.text--accent-2 {
  color: #ff5252 !important;
  caret-color: #ff5252 !important;
}
.red.accent-3 {
  background-color: #ff1744 !important;
  border-color: #ff1744 !important;
}
.red--text.text--accent-3 {
  color: #ff1744 !important;
  caret-color: #ff1744 !important;
}
.red.accent-4 {
  background-color: #d50000 !important;
  border-color: #d50000 !important;
}
.red--text.text--accent-4 {
  color: #d50000 !important;
  caret-color: #d50000 !important;
}
.pink {
  background-color: #e91e63 !important;
  border-color: #e91e63 !important;
}
.pink--text {
  color: #e91e63 !important;
  caret-color: #e91e63 !important;
}
.pink.lighten-5 {
  background-color: #fce4ec !important;
  border-color: #fce4ec !important;
}
.pink--text.text--lighten-5 {
  color: #fce4ec !important;
  caret-color: #fce4ec !important;
}
.pink.lighten-4 {
  background-color: #f8bbd0 !important;
  border-color: #f8bbd0 !important;
}
.pink--text.text--lighten-4 {
  color: #f8bbd0 !important;
  caret-color: #f8bbd0 !important;
}
.pink.lighten-3 {
  background-color: #f48fb1 !important;
  border-color: #f48fb1 !important;
}
.pink--text.text--lighten-3 {
  color: #f48fb1 !important;
  caret-color: #f48fb1 !important;
}
.pink.lighten-2 {
  background-color: #f06292 !important;
  border-color: #f06292 !important;
}
.pink--text.text--lighten-2 {
  color: #f06292 !important;
  caret-color: #f06292 !important;
}
.pink.lighten-1 {
  background-color: #ec407a !important;
  border-color: #ec407a !important;
}
.pink--text.text--lighten-1 {
  color: #ec407a !important;
  caret-color: #ec407a !important;
}
.pink.darken-1 {
  background-color: #d81b60 !important;
  border-color: #d81b60 !important;
}
.pink--text.text--darken-1 {
  color: #d81b60 !important;
  caret-color: #d81b60 !important;
}
.pink.darken-2 {
  background-color: #c2185b !important;
  border-color: #c2185b !important;
}
.pink--text.text--darken-2 {
  color: #c2185b !important;
  caret-color: #c2185b !important;
}
.pink.darken-3 {
  background-color: #ad1457 !important;
  border-color: #ad1457 !important;
}
.pink--text.text--darken-3 {
  color: #ad1457 !important;
  caret-color: #ad1457 !important;
}
.pink.darken-4 {
  background-color: #880e4f !important;
  border-color: #880e4f !important;
}
.pink--text.text--darken-4 {
  color: #880e4f !important;
  caret-color: #880e4f !important;
}
.pink.accent-1 {
  background-color: #ff80ab !important;
  border-color: #ff80ab !important;
}
.pink--text.text--accent-1 {
  color: #ff80ab !important;
  caret-color: #ff80ab !important;
}
.pink.accent-2 {
  background-color: #ff4081 !important;
  border-color: #ff4081 !important;
}
.pink--text.text--accent-2 {
  color: #ff4081 !important;
  caret-color: #ff4081 !important;
}
.pink.accent-3 {
  background-color: #f50057 !important;
  border-color: #f50057 !important;
}
.pink--text.text--accent-3 {
  color: #f50057 !important;
  caret-color: #f50057 !important;
}
.pink.accent-4 {
  background-color: #c51162 !important;
  border-color: #c51162 !important;
}
.pink--text.text--accent-4 {
  color: #c51162 !important;
  caret-color: #c51162 !important;
}
.purple {
  background-color: #9c27b0 !important;
  border-color: #9c27b0 !important;
}
.purple--text {
  color: #9c27b0 !important;
  caret-color: #9c27b0 !important;
}
.purple.lighten-5 {
  background-color: #f3e5f5 !important;
  border-color: #f3e5f5 !important;
}
.purple--text.text--lighten-5 {
  color: #f3e5f5 !important;
  caret-color: #f3e5f5 !important;
}
.purple.lighten-4 {
  background-color: #e1bee7 !important;
  border-color: #e1bee7 !important;
}
.purple--text.text--lighten-4 {
  color: #e1bee7 !important;
  caret-color: #e1bee7 !important;
}
.purple.lighten-3 {
  background-color: #ce93d8 !important;
  border-color: #ce93d8 !important;
}
.purple--text.text--lighten-3 {
  color: #ce93d8 !important;
  caret-color: #ce93d8 !important;
}
.purple.lighten-2 {
  background-color: #ba68c8 !important;
  border-color: #ba68c8 !important;
}
.purple--text.text--lighten-2 {
  color: #ba68c8 !important;
  caret-color: #ba68c8 !important;
}
.purple.lighten-1 {
  background-color: #ab47bc !important;
  border-color: #ab47bc !important;
}
.purple--text.text--lighten-1 {
  color: #ab47bc !important;
  caret-color: #ab47bc !important;
}
.purple.darken-1 {
  background-color: #8e24aa !important;
  border-color: #8e24aa !important;
}
.purple--text.text--darken-1 {
  color: #8e24aa !important;
  caret-color: #8e24aa !important;
}
.purple.darken-2 {
  background-color: #7b1fa2 !important;
  border-color: #7b1fa2 !important;
}
.purple--text.text--darken-2 {
  color: #7b1fa2 !important;
  caret-color: #7b1fa2 !important;
}
.purple.darken-3 {
  background-color: #6a1b9a !important;
  border-color: #6a1b9a !important;
}
.purple--text.text--darken-3 {
  color: #6a1b9a !important;
  caret-color: #6a1b9a !important;
}
.purple.darken-4 {
  background-color: #4a148c !important;
  border-color: #4a148c !important;
}
.purple--text.text--darken-4 {
  color: #4a148c !important;
  caret-color: #4a148c !important;
}
.purple.accent-1 {
  background-color: #ea80fc !important;
  border-color: #ea80fc !important;
}
.purple--text.text--accent-1 {
  color: #ea80fc !important;
  caret-color: #ea80fc !important;
}
.purple.accent-2 {
  background-color: #e040fb !important;
  border-color: #e040fb !important;
}
.purple--text.text--accent-2 {
  color: #e040fb !important;
  caret-color: #e040fb !important;
}
.purple.accent-3 {
  background-color: #d500f9 !important;
  border-color: #d500f9 !important;
}
.purple--text.text--accent-3 {
  color: #d500f9 !important;
  caret-color: #d500f9 !important;
}
.purple.accent-4 {
  background-color: #a0f !important;
  border-color: #a0f !important;
}
.purple--text.text--accent-4 {
  color: #a0f !important;
  caret-color: #a0f !important;
}
.deep-purple {
  background-color: #673ab7 !important;
  border-color: #673ab7 !important;
}
.deep-purple--text {
  color: #673ab7 !important;
  caret-color: #673ab7 !important;
}
.deep-purple.lighten-5 {
  background-color: #ede7f6 !important;
  border-color: #ede7f6 !important;
}
.deep-purple--text.text--lighten-5 {
  color: #ede7f6 !important;
  caret-color: #ede7f6 !important;
}
.deep-purple.lighten-4 {
  background-color: #d1c4e9 !important;
  border-color: #d1c4e9 !important;
}
.deep-purple--text.text--lighten-4 {
  color: #d1c4e9 !important;
  caret-color: #d1c4e9 !important;
}
.deep-purple.lighten-3 {
  background-color: #b39ddb !important;
  border-color: #b39ddb !important;
}
.deep-purple--text.text--lighten-3 {
  color: #b39ddb !important;
  caret-color: #b39ddb !important;
}
.deep-purple.lighten-2 {
  background-color: #9575cd !important;
  border-color: #9575cd !important;
}
.deep-purple--text.text--lighten-2 {
  color: #9575cd !important;
  caret-color: #9575cd !important;
}
.deep-purple.lighten-1 {
  background-color: #7e57c2 !important;
  border-color: #7e57c2 !important;
}
.deep-purple--text.text--lighten-1 {
  color: #7e57c2 !important;
  caret-color: #7e57c2 !important;
}
.deep-purple.darken-1 {
  background-color: #5e35b1 !important;
  border-color: #5e35b1 !important;
}
.deep-purple--text.text--darken-1 {
  color: #5e35b1 !important;
  caret-color: #5e35b1 !important;
}
.deep-purple.darken-2 {
  background-color: #512da8 !important;
  border-color: #512da8 !important;
}
.deep-purple--text.text--darken-2 {
  color: #512da8 !important;
  caret-color: #512da8 !important;
}
.deep-purple.darken-3 {
  background-color: #4527a0 !important;
  border-color: #4527a0 !important;
}
.deep-purple--text.text--darken-3 {
  color: #4527a0 !important;
  caret-color: #4527a0 !important;
}
.deep-purple.darken-4 {
  background-color: #311b92 !important;
  border-color: #311b92 !important;
}
.deep-purple--text.text--darken-4 {
  color: #311b92 !important;
  caret-color: #311b92 !important;
}
.deep-purple.accent-1 {
  background-color: #b388ff !important;
  border-color: #b388ff !important;
}
.deep-purple--text.text--accent-1 {
  color: #b388ff !important;
  caret-color: #b388ff !important;
}
.deep-purple.accent-2 {
  background-color: #7c4dff !important;
  border-color: #7c4dff !important;
}
.deep-purple--text.text--accent-2 {
  color: #7c4dff !important;
  caret-color: #7c4dff !important;
}
.deep-purple.accent-3 {
  background-color: #651fff !important;
  border-color: #651fff !important;
}
.deep-purple--text.text--accent-3 {
  color: #651fff !important;
  caret-color: #651fff !important;
}
.deep-purple.accent-4 {
  background-color: #6200ea !important;
  border-color: #6200ea !important;
}
.deep-purple--text.text--accent-4 {
  color: #6200ea !important;
  caret-color: #6200ea !important;
}
.indigo {
  background-color: #3f51b5 !important;
  border-color: #3f51b5 !important;
}
.indigo--text {
  color: #3f51b5 !important;
  caret-color: #3f51b5 !important;
}
.indigo.lighten-5 {
  background-color: #e8eaf6 !important;
  border-color: #e8eaf6 !important;
}
.indigo--text.text--lighten-5 {
  color: #e8eaf6 !important;
  caret-color: #e8eaf6 !important;
}
.indigo.lighten-4 {
  background-color: #c5cae9 !important;
  border-color: #c5cae9 !important;
}
.indigo--text.text--lighten-4 {
  color: #c5cae9 !important;
  caret-color: #c5cae9 !important;
}
.indigo.lighten-3 {
  background-color: #9fa8da !important;
  border-color: #9fa8da !important;
}
.indigo--text.text--lighten-3 {
  color: #9fa8da !important;
  caret-color: #9fa8da !important;
}
.indigo.lighten-2 {
  background-color: #7986cb !important;
  border-color: #7986cb !important;
}
.indigo--text.text--lighten-2 {
  color: #7986cb !important;
  caret-color: #7986cb !important;
}
.indigo.lighten-1 {
  background-color: #5c6bc0 !important;
  border-color: #5c6bc0 !important;
}
.indigo--text.text--lighten-1 {
  color: #5c6bc0 !important;
  caret-color: #5c6bc0 !important;
}
.indigo.darken-1 {
  background-color: #3949ab !important;
  border-color: #3949ab !important;
}
.indigo--text.text--darken-1 {
  color: #3949ab !important;
  caret-color: #3949ab !important;
}
.indigo.darken-2 {
  background-color: #303f9f !important;
  border-color: #303f9f !important;
}
.indigo--text.text--darken-2 {
  color: #303f9f !important;
  caret-color: #303f9f !important;
}
.indigo.darken-3 {
  background-color: #283593 !important;
  border-color: #283593 !important;
}
.indigo--text.text--darken-3 {
  color: #283593 !important;
  caret-color: #283593 !important;
}
.indigo.darken-4 {
  background-color: #1a237e !important;
  border-color: #1a237e !important;
}
.indigo--text.text--darken-4 {
  color: #1a237e !important;
  caret-color: #1a237e !important;
}
.indigo.accent-1 {
  background-color: #8c9eff !important;
  border-color: #8c9eff !important;
}
.indigo--text.text--accent-1 {
  color: #8c9eff !important;
  caret-color: #8c9eff !important;
}
.indigo.accent-2 {
  background-color: #536dfe !important;
  border-color: #536dfe !important;
}
.indigo--text.text--accent-2 {
  color: #536dfe !important;
  caret-color: #536dfe !important;
}
.indigo.accent-3 {
  background-color: #3d5afe !important;
  border-color: #3d5afe !important;
}
.indigo--text.text--accent-3 {
  color: #3d5afe !important;
  caret-color: #3d5afe !important;
}
.indigo.accent-4 {
  background-color: #304ffe !important;
  border-color: #304ffe !important;
}
.indigo--text.text--accent-4 {
  color: #304ffe !important;
  caret-color: #304ffe !important;
}
.blue {
  background-color: #2196f3 !important;
  border-color: #2196f3 !important;
}
.blue--text {
  color: #2196f3 !important;
  caret-color: #2196f3 !important;
}
.blue.lighten-5 {
  background-color: #e3f2fd !important;
  border-color: #e3f2fd !important;
}
.blue--text.text--lighten-5 {
  color: #e3f2fd !important;
  caret-color: #e3f2fd !important;
}
.blue.lighten-4 {
  background-color: #bbdefb !important;
  border-color: #bbdefb !important;
}
.blue--text.text--lighten-4 {
  color: #bbdefb !important;
  caret-color: #bbdefb !important;
}
.blue.lighten-3 {
  background-color: #90caf9 !important;
  border-color: #90caf9 !important;
}
.blue--text.text--lighten-3 {
  color: #90caf9 !important;
  caret-color: #90caf9 !important;
}
.blue.lighten-2 {
  background-color: #64b5f6 !important;
  border-color: #64b5f6 !important;
}
.blue--text.text--lighten-2 {
  color: #64b5f6 !important;
  caret-color: #64b5f6 !important;
}
.blue.lighten-1 {
  background-color: #42a5f5 !important;
  border-color: #42a5f5 !important;
}
.blue--text.text--lighten-1 {
  color: #42a5f5 !important;
  caret-color: #42a5f5 !important;
}
.blue.darken-1 {
  background-color: #1e88e5 !important;
  border-color: #1e88e5 !important;
}
.blue--text.text--darken-1 {
  color: #1e88e5 !important;
  caret-color: #1e88e5 !important;
}
.blue.darken-2 {
  background-color: #1976d2 !important;
  border-color: #1976d2 !important;
}
.blue--text.text--darken-2 {
  color: #1976d2 !important;
  caret-color: #1976d2 !important;
}
.blue.darken-3 {
  background-color: #1565c0 !important;
  border-color: #1565c0 !important;
}
.blue--text.text--darken-3 {
  color: #1565c0 !important;
  caret-color: #1565c0 !important;
}
.blue.darken-4 {
  background-color: #0d47a1 !important;
  border-color: #0d47a1 !important;
}
.blue--text.text--darken-4 {
  color: #0d47a1 !important;
  caret-color: #0d47a1 !important;
}
.blue.accent-1 {
  background-color: #82b1ff !important;
  border-color: #82b1ff !important;
}
.blue--text.text--accent-1 {
  color: #82b1ff !important;
  caret-color: #82b1ff !important;
}
.blue.accent-2 {
  background-color: #448aff !important;
  border-color: #448aff !important;
}
.blue--text.text--accent-2 {
  color: #448aff !important;
  caret-color: #448aff !important;
}
.blue.accent-3 {
  background-color: #2979ff !important;
  border-color: #2979ff !important;
}
.blue--text.text--accent-3 {
  color: #2979ff !important;
  caret-color: #2979ff !important;
}
.blue.accent-4 {
  background-color: #2962ff !important;
  border-color: #2962ff !important;
}
.blue--text.text--accent-4 {
  color: #2962ff !important;
  caret-color: #2962ff !important;
}
.light-blue {
  background-color: #03a9f4 !important;
  border-color: #03a9f4 !important;
}
.light-blue--text {
  color: #03a9f4 !important;
  caret-color: #03a9f4 !important;
}
.light-blue.lighten-5 {
  background-color: #e1f5fe !important;
  border-color: #e1f5fe !important;
}
.light-blue--text.text--lighten-5 {
  color: #e1f5fe !important;
  caret-color: #e1f5fe !important;
}
.light-blue.lighten-4 {
  background-color: #b3e5fc !important;
  border-color: #b3e5fc !important;
}
.light-blue--text.text--lighten-4 {
  color: #b3e5fc !important;
  caret-color: #b3e5fc !important;
}
.light-blue.lighten-3 {
  background-color: #81d4fa !important;
  border-color: #81d4fa !important;
}
.light-blue--text.text--lighten-3 {
  color: #81d4fa !important;
  caret-color: #81d4fa !important;
}
.light-blue.lighten-2 {
  background-color: #4fc3f7 !important;
  border-color: #4fc3f7 !important;
}
.light-blue--text.text--lighten-2 {
  color: #4fc3f7 !important;
  caret-color: #4fc3f7 !important;
}
.light-blue.lighten-1 {
  background-color: #29b6f6 !important;
  border-color: #29b6f6 !important;
}
.light-blue--text.text--lighten-1 {
  color: #29b6f6 !important;
  caret-color: #29b6f6 !important;
}
.light-blue.darken-1 {
  background-color: #039be5 !important;
  border-color: #039be5 !important;
}
.light-blue--text.text--darken-1 {
  color: #039be5 !important;
  caret-color: #039be5 !important;
}
.light-blue.darken-2 {
  background-color: #0288d1 !important;
  border-color: #0288d1 !important;
}
.light-blue--text.text--darken-2 {
  color: #0288d1 !important;
  caret-color: #0288d1 !important;
}
.light-blue.darken-3 {
  background-color: #0277bd !important;
  border-color: #0277bd !important;
}
.light-blue--text.text--darken-3 {
  color: #0277bd !important;
  caret-color: #0277bd !important;
}
.light-blue.darken-4 {
  background-color: #01579b !important;
  border-color: #01579b !important;
}
.light-blue--text.text--darken-4 {
  color: #01579b !important;
  caret-color: #01579b !important;
}
.light-blue.accent-1 {
  background-color: #80d8ff !important;
  border-color: #80d8ff !important;
}
.light-blue--text.text--accent-1 {
  color: #80d8ff !important;
  caret-color: #80d8ff !important;
}
.light-blue.accent-2 {
  background-color: #40c4ff !important;
  border-color: #40c4ff !important;
}
.light-blue--text.text--accent-2 {
  color: #40c4ff !important;
  caret-color: #40c4ff !important;
}
.light-blue.accent-3 {
  background-color: #00b0ff !important;
  border-color: #00b0ff !important;
}
.light-blue--text.text--accent-3 {
  color: #00b0ff !important;
  caret-color: #00b0ff !important;
}
.light-blue.accent-4 {
  background-color: #0091ea !important;
  border-color: #0091ea !important;
}
.light-blue--text.text--accent-4 {
  color: #0091ea !important;
  caret-color: #0091ea !important;
}
.cyan {
  background-color: #00bcd4 !important;
  border-color: #00bcd4 !important;
}
.cyan--text {
  color: #00bcd4 !important;
  caret-color: #00bcd4 !important;
}
.cyan.lighten-5 {
  background-color: #e0f7fa !important;
  border-color: #e0f7fa !important;
}
.cyan--text.text--lighten-5 {
  color: #e0f7fa !important;
  caret-color: #e0f7fa !important;
}
.cyan.lighten-4 {
  background-color: #b2ebf2 !important;
  border-color: #b2ebf2 !important;
}
.cyan--text.text--lighten-4 {
  color: #b2ebf2 !important;
  caret-color: #b2ebf2 !important;
}
.cyan.lighten-3 {
  background-color: #80deea !important;
  border-color: #80deea !important;
}
.cyan--text.text--lighten-3 {
  color: #80deea !important;
  caret-color: #80deea !important;
}
.cyan.lighten-2 {
  background-color: #4dd0e1 !important;
  border-color: #4dd0e1 !important;
}
.cyan--text.text--lighten-2 {
  color: #4dd0e1 !important;
  caret-color: #4dd0e1 !important;
}
.cyan.lighten-1 {
  background-color: #26c6da !important;
  border-color: #26c6da !important;
}
.cyan--text.text--lighten-1 {
  color: #26c6da !important;
  caret-color: #26c6da !important;
}
.cyan.darken-1 {
  background-color: #00acc1 !important;
  border-color: #00acc1 !important;
}
.cyan--text.text--darken-1 {
  color: #00acc1 !important;
  caret-color: #00acc1 !important;
}
.cyan.darken-2 {
  background-color: #0097a7 !important;
  border-color: #0097a7 !important;
}
.cyan--text.text--darken-2 {
  color: #0097a7 !important;
  caret-color: #0097a7 !important;
}
.cyan.darken-3 {
  background-color: #00838f !important;
  border-color: #00838f !important;
}
.cyan--text.text--darken-3 {
  color: #00838f !important;
  caret-color: #00838f !important;
}
.cyan.darken-4 {
  background-color: #006064 !important;
  border-color: #006064 !important;
}
.cyan--text.text--darken-4 {
  color: #006064 !important;
  caret-color: #006064 !important;
}
.cyan.accent-1 {
  background-color: #84ffff !important;
  border-color: #84ffff !important;
}
.cyan--text.text--accent-1 {
  color: #84ffff !important;
  caret-color: #84ffff !important;
}
.cyan.accent-2 {
  background-color: #18ffff !important;
  border-color: #18ffff !important;
}
.cyan--text.text--accent-2 {
  color: #18ffff !important;
  caret-color: #18ffff !important;
}
.cyan.accent-3 {
  background-color: #00e5ff !important;
  border-color: #00e5ff !important;
}
.cyan--text.text--accent-3 {
  color: #00e5ff !important;
  caret-color: #00e5ff !important;
}
.cyan.accent-4 {
  background-color: #00b8d4 !important;
  border-color: #00b8d4 !important;
}
.cyan--text.text--accent-4 {
  color: #00b8d4 !important;
  caret-color: #00b8d4 !important;
}
.teal {
  background-color: #009688 !important;
  border-color: #009688 !important;
}
.teal--text {
  color: #009688 !important;
  caret-color: #009688 !important;
}
.teal.lighten-5 {
  background-color: #e0f2f1 !important;
  border-color: #e0f2f1 !important;
}
.teal--text.text--lighten-5 {
  color: #e0f2f1 !important;
  caret-color: #e0f2f1 !important;
}
.teal.lighten-4 {
  background-color: #b2dfdb !important;
  border-color: #b2dfdb !important;
}
.teal--text.text--lighten-4 {
  color: #b2dfdb !important;
  caret-color: #b2dfdb !important;
}
.teal.lighten-3 {
  background-color: #80cbc4 !important;
  border-color: #80cbc4 !important;
}
.teal--text.text--lighten-3 {
  color: #80cbc4 !important;
  caret-color: #80cbc4 !important;
}
.teal.lighten-2 {
  background-color: #4db6ac !important;
  border-color: #4db6ac !important;
}
.teal--text.text--lighten-2 {
  color: #4db6ac !important;
  caret-color: #4db6ac !important;
}
.teal.lighten-1 {
  background-color: #26a69a !important;
  border-color: #26a69a !important;
}
.teal--text.text--lighten-1 {
  color: #26a69a !important;
  caret-color: #26a69a !important;
}
.teal.darken-1 {
  background-color: #00897b !important;
  border-color: #00897b !important;
}
.teal--text.text--darken-1 {
  color: #00897b !important;
  caret-color: #00897b !important;
}
.teal.darken-2 {
  background-color: #00796b !important;
  border-color: #00796b !important;
}
.teal--text.text--darken-2 {
  color: #00796b !important;
  caret-color: #00796b !important;
}
.teal.darken-3 {
  background-color: #00695c !important;
  border-color: #00695c !important;
}
.teal--text.text--darken-3 {
  color: #00695c !important;
  caret-color: #00695c !important;
}
.teal.darken-4 {
  background-color: #004d40 !important;
  border-color: #004d40 !important;
}
.teal--text.text--darken-4 {
  color: #004d40 !important;
  caret-color: #004d40 !important;
}
.teal.accent-1 {
  background-color: #a7ffeb !important;
  border-color: #a7ffeb !important;
}
.teal--text.text--accent-1 {
  color: #a7ffeb !important;
  caret-color: #a7ffeb !important;
}
.teal.accent-2 {
  background-color: #64ffda !important;
  border-color: #64ffda !important;
}
.teal--text.text--accent-2 {
  color: #64ffda !important;
  caret-color: #64ffda !important;
}
.teal.accent-3 {
  background-color: #1de9b6 !important;
  border-color: #1de9b6 !important;
}
.teal--text.text--accent-3 {
  color: #1de9b6 !important;
  caret-color: #1de9b6 !important;
}
.teal.accent-4 {
  background-color: #00bfa5 !important;
  border-color: #00bfa5 !important;
}
.teal--text.text--accent-4 {
  color: #00bfa5 !important;
  caret-color: #00bfa5 !important;
}
.green {
  background-color: #4caf50 !important;
  border-color: #4caf50 !important;
}
.green--text {
  color: #4caf50 !important;
  caret-color: #4caf50 !important;
}
.green.lighten-5 {
  background-color: #e8f5e9 !important;
  border-color: #e8f5e9 !important;
}
.green--text.text--lighten-5 {
  color: #e8f5e9 !important;
  caret-color: #e8f5e9 !important;
}
.green.lighten-4 {
  background-color: #c8e6c9 !important;
  border-color: #c8e6c9 !important;
}
.green--text.text--lighten-4 {
  color: #c8e6c9 !important;
  caret-color: #c8e6c9 !important;
}
.green.lighten-3 {
  background-color: #a5d6a7 !important;
  border-color: #a5d6a7 !important;
}
.green--text.text--lighten-3 {
  color: #a5d6a7 !important;
  caret-color: #a5d6a7 !important;
}
.green.lighten-2 {
  background-color: #81c784 !important;
  border-color: #81c784 !important;
}
.green--text.text--lighten-2 {
  color: #81c784 !important;
  caret-color: #81c784 !important;
}
.green.lighten-1 {
  background-color: #66bb6a !important;
  border-color: #66bb6a !important;
}
.green--text.text--lighten-1 {
  color: #66bb6a !important;
  caret-color: #66bb6a !important;
}
.green.darken-1 {
  background-color: #43a047 !important;
  border-color: #43a047 !important;
}
.green--text.text--darken-1 {
  color: #43a047 !important;
  caret-color: #43a047 !important;
}
.green.darken-2 {
  background-color: #388e3c !important;
  border-color: #388e3c !important;
}
.green--text.text--darken-2 {
  color: #388e3c !important;
  caret-color: #388e3c !important;
}
.green.darken-3 {
  background-color: #2e7d32 !important;
  border-color: #2e7d32 !important;
}
.green--text.text--darken-3 {
  color: #2e7d32 !important;
  caret-color: #2e7d32 !important;
}
.green.darken-4 {
  background-color: #1b5e20 !important;
  border-color: #1b5e20 !important;
}
.green--text.text--darken-4 {
  color: #1b5e20 !important;
  caret-color: #1b5e20 !important;
}
.green.accent-1 {
  background-color: #b9f6ca !important;
  border-color: #b9f6ca !important;
}
.green--text.text--accent-1 {
  color: #b9f6ca !important;
  caret-color: #b9f6ca !important;
}
.green.accent-2 {
  background-color: #69f0ae !important;
  border-color: #69f0ae !important;
}
.green--text.text--accent-2 {
  color: #69f0ae !important;
  caret-color: #69f0ae !important;
}
.green.accent-3 {
  background-color: #00e676 !important;
  border-color: #00e676 !important;
}
.green--text.text--accent-3 {
  color: #00e676 !important;
  caret-color: #00e676 !important;
}
.green.accent-4 {
  background-color: #00c853 !important;
  border-color: #00c853 !important;
}
.green--text.text--accent-4 {
  color: #00c853 !important;
  caret-color: #00c853 !important;
}
.light-green {
  background-color: #8bc34a !important;
  border-color: #8bc34a !important;
}
.light-green--text {
  color: #8bc34a !important;
  caret-color: #8bc34a !important;
}
.light-green.lighten-5 {
  background-color: #f1f8e9 !important;
  border-color: #f1f8e9 !important;
}
.light-green--text.text--lighten-5 {
  color: #f1f8e9 !important;
  caret-color: #f1f8e9 !important;
}
.light-green.lighten-4 {
  background-color: #dcedc8 !important;
  border-color: #dcedc8 !important;
}
.light-green--text.text--lighten-4 {
  color: #dcedc8 !important;
  caret-color: #dcedc8 !important;
}
.light-green.lighten-3 {
  background-color: #c5e1a5 !important;
  border-color: #c5e1a5 !important;
}
.light-green--text.text--lighten-3 {
  color: #c5e1a5 !important;
  caret-color: #c5e1a5 !important;
}
.light-green.lighten-2 {
  background-color: #aed581 !important;
  border-color: #aed581 !important;
}
.light-green--text.text--lighten-2 {
  color: #aed581 !important;
  caret-color: #aed581 !important;
}
.light-green.lighten-1 {
  background-color: #9ccc65 !important;
  border-color: #9ccc65 !important;
}
.light-green--text.text--lighten-1 {
  color: #9ccc65 !important;
  caret-color: #9ccc65 !important;
}
.light-green.darken-1 {
  background-color: #7cb342 !important;
  border-color: #7cb342 !important;
}
.light-green--text.text--darken-1 {
  color: #7cb342 !important;
  caret-color: #7cb342 !important;
}
.light-green.darken-2 {
  background-color: #689f38 !important;
  border-color: #689f38 !important;
}
.light-green--text.text--darken-2 {
  color: #689f38 !important;
  caret-color: #689f38 !important;
}
.light-green.darken-3 {
  background-color: #558b2f !important;
  border-color: #558b2f !important;
}
.light-green--text.text--darken-3 {
  color: #558b2f !important;
  caret-color: #558b2f !important;
}
.light-green.darken-4 {
  background-color: #33691e !important;
  border-color: #33691e !important;
}
.light-green--text.text--darken-4 {
  color: #33691e !important;
  caret-color: #33691e !important;
}
.light-green.accent-1 {
  background-color: #ccff90 !important;
  border-color: #ccff90 !important;
}
.light-green--text.text--accent-1 {
  color: #ccff90 !important;
  caret-color: #ccff90 !important;
}
.light-green.accent-2 {
  background-color: #b2ff59 !important;
  border-color: #b2ff59 !important;
}
.light-green--text.text--accent-2 {
  color: #b2ff59 !important;
  caret-color: #b2ff59 !important;
}
.light-green.accent-3 {
  background-color: #76ff03 !important;
  border-color: #76ff03 !important;
}
.light-green--text.text--accent-3 {
  color: #76ff03 !important;
  caret-color: #76ff03 !important;
}
.light-green.accent-4 {
  background-color: #64dd17 !important;
  border-color: #64dd17 !important;
}
.light-green--text.text--accent-4 {
  color: #64dd17 !important;
  caret-color: #64dd17 !important;
}
.lime {
  background-color: #cddc39 !important;
  border-color: #cddc39 !important;
}
.lime--text {
  color: #cddc39 !important;
  caret-color: #cddc39 !important;
}
.lime.lighten-5 {
  background-color: #f9fbe7 !important;
  border-color: #f9fbe7 !important;
}
.lime--text.text--lighten-5 {
  color: #f9fbe7 !important;
  caret-color: #f9fbe7 !important;
}
.lime.lighten-4 {
  background-color: #f0f4c3 !important;
  border-color: #f0f4c3 !important;
}
.lime--text.text--lighten-4 {
  color: #f0f4c3 !important;
  caret-color: #f0f4c3 !important;
}
.lime.lighten-3 {
  background-color: #e6ee9c !important;
  border-color: #e6ee9c !important;
}
.lime--text.text--lighten-3 {
  color: #e6ee9c !important;
  caret-color: #e6ee9c !important;
}
.lime.lighten-2 {
  background-color: #dce775 !important;
  border-color: #dce775 !important;
}
.lime--text.text--lighten-2 {
  color: #dce775 !important;
  caret-color: #dce775 !important;
}
.lime.lighten-1 {
  background-color: #d4e157 !important;
  border-color: #d4e157 !important;
}
.lime--text.text--lighten-1 {
  color: #d4e157 !important;
  caret-color: #d4e157 !important;
}
.lime.darken-1 {
  background-color: #c0ca33 !important;
  border-color: #c0ca33 !important;
}
.lime--text.text--darken-1 {
  color: #c0ca33 !important;
  caret-color: #c0ca33 !important;
}
.lime.darken-2 {
  background-color: #afb42b !important;
  border-color: #afb42b !important;
}
.lime--text.text--darken-2 {
  color: #afb42b !important;
  caret-color: #afb42b !important;
}
.lime.darken-3 {
  background-color: #9e9d24 !important;
  border-color: #9e9d24 !important;
}
.lime--text.text--darken-3 {
  color: #9e9d24 !important;
  caret-color: #9e9d24 !important;
}
.lime.darken-4 {
  background-color: #827717 !important;
  border-color: #827717 !important;
}
.lime--text.text--darken-4 {
  color: #827717 !important;
  caret-color: #827717 !important;
}
.lime.accent-1 {
  background-color: #f4ff81 !important;
  border-color: #f4ff81 !important;
}
.lime--text.text--accent-1 {
  color: #f4ff81 !important;
  caret-color: #f4ff81 !important;
}
.lime.accent-2 {
  background-color: #eeff41 !important;
  border-color: #eeff41 !important;
}
.lime--text.text--accent-2 {
  color: #eeff41 !important;
  caret-color: #eeff41 !important;
}
.lime.accent-3 {
  background-color: #c6ff00 !important;
  border-color: #c6ff00 !important;
}
.lime--text.text--accent-3 {
  color: #c6ff00 !important;
  caret-color: #c6ff00 !important;
}
.lime.accent-4 {
  background-color: #aeea00 !important;
  border-color: #aeea00 !important;
}
.lime--text.text--accent-4 {
  color: #aeea00 !important;
  caret-color: #aeea00 !important;
}
.yellow {
  background-color: #ffeb3b !important;
  border-color: #ffeb3b !important;
}
.yellow--text {
  color: #ffeb3b !important;
  caret-color: #ffeb3b !important;
}
.yellow.lighten-5 {
  background-color: #fffde7 !important;
  border-color: #fffde7 !important;
}
.yellow--text.text--lighten-5 {
  color: #fffde7 !important;
  caret-color: #fffde7 !important;
}
.yellow.lighten-4 {
  background-color: #fff9c4 !important;
  border-color: #fff9c4 !important;
}
.yellow--text.text--lighten-4 {
  color: #fff9c4 !important;
  caret-color: #fff9c4 !important;
}
.yellow.lighten-3 {
  background-color: #fff59d !important;
  border-color: #fff59d !important;
}
.yellow--text.text--lighten-3 {
  color: #fff59d !important;
  caret-color: #fff59d !important;
}
.yellow.lighten-2 {
  background-color: #fff176 !important;
  border-color: #fff176 !important;
}
.yellow--text.text--lighten-2 {
  color: #fff176 !important;
  caret-color: #fff176 !important;
}
.yellow.lighten-1 {
  background-color: #ffee58 !important;
  border-color: #ffee58 !important;
}
.yellow--text.text--lighten-1 {
  color: #ffee58 !important;
  caret-color: #ffee58 !important;
}
.yellow.darken-1 {
  background-color: #fdd835 !important;
  border-color: #fdd835 !important;
}
.yellow--text.text--darken-1 {
  color: #fdd835 !important;
  caret-color: #fdd835 !important;
}
.yellow.darken-2 {
  background-color: #fbc02d !important;
  border-color: #fbc02d !important;
}
.yellow--text.text--darken-2 {
  color: #fbc02d !important;
  caret-color: #fbc02d !important;
}
.yellow.darken-3 {
  background-color: #f9a825 !important;
  border-color: #f9a825 !important;
}
.yellow--text.text--darken-3 {
  color: #f9a825 !important;
  caret-color: #f9a825 !important;
}
.yellow.darken-4 {
  background-color: #f57f17 !important;
  border-color: #f57f17 !important;
}
.yellow--text.text--darken-4 {
  color: #f57f17 !important;
  caret-color: #f57f17 !important;
}
.yellow.accent-1 {
  background-color: #ffff8d !important;
  border-color: #ffff8d !important;
}
.yellow--text.text--accent-1 {
  color: #ffff8d !important;
  caret-color: #ffff8d !important;
}
.yellow.accent-2 {
  background-color: #ff0 !important;
  border-color: #ff0 !important;
}
.yellow--text.text--accent-2 {
  color: #ff0 !important;
  caret-color: #ff0 !important;
}
.yellow.accent-3 {
  background-color: #ffea00 !important;
  border-color: #ffea00 !important;
}
.yellow--text.text--accent-3 {
  color: #ffea00 !important;
  caret-color: #ffea00 !important;
}
.yellow.accent-4 {
  background-color: #ffd600 !important;
  border-color: #ffd600 !important;
}
.yellow--text.text--accent-4 {
  color: #ffd600 !important;
  caret-color: #ffd600 !important;
}
.amber {
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}
.amber--text {
  color: #ffc107 !important;
  caret-color: #ffc107 !important;
}
.amber.lighten-5 {
  background-color: #fff8e1 !important;
  border-color: #fff8e1 !important;
}
.amber--text.text--lighten-5 {
  color: #fff8e1 !important;
  caret-color: #fff8e1 !important;
}
.amber.lighten-4 {
  background-color: #ffecb3 !important;
  border-color: #ffecb3 !important;
}
.amber--text.text--lighten-4 {
  color: #ffecb3 !important;
  caret-color: #ffecb3 !important;
}
.amber.lighten-3 {
  background-color: #ffe082 !important;
  border-color: #ffe082 !important;
}
.amber--text.text--lighten-3 {
  color: #ffe082 !important;
  caret-color: #ffe082 !important;
}
.amber.lighten-2 {
  background-color: #ffd54f !important;
  border-color: #ffd54f !important;
}
.amber--text.text--lighten-2 {
  color: #ffd54f !important;
  caret-color: #ffd54f !important;
}
.amber.lighten-1 {
  background-color: #ffca28 !important;
  border-color: #ffca28 !important;
}
.amber--text.text--lighten-1 {
  color: #ffca28 !important;
  caret-color: #ffca28 !important;
}
.amber.darken-1 {
  background-color: #ffb300 !important;
  border-color: #ffb300 !important;
}
.amber--text.text--darken-1 {
  color: #ffb300 !important;
  caret-color: #ffb300 !important;
}
.amber.darken-2 {
  background-color: #ffa000 !important;
  border-color: #ffa000 !important;
}
.amber--text.text--darken-2 {
  color: #ffa000 !important;
  caret-color: #ffa000 !important;
}
.amber.darken-3 {
  background-color: #ff8f00 !important;
  border-color: #ff8f00 !important;
}
.amber--text.text--darken-3 {
  color: #ff8f00 !important;
  caret-color: #ff8f00 !important;
}
.amber.darken-4 {
  background-color: #ff6f00 !important;
  border-color: #ff6f00 !important;
}
.amber--text.text--darken-4 {
  color: #ff6f00 !important;
  caret-color: #ff6f00 !important;
}
.amber.accent-1 {
  background-color: #ffe57f !important;
  border-color: #ffe57f !important;
}
.amber--text.text--accent-1 {
  color: #ffe57f !important;
  caret-color: #ffe57f !important;
}
.amber.accent-2 {
  background-color: #ffd740 !important;
  border-color: #ffd740 !important;
}
.amber--text.text--accent-2 {
  color: #ffd740 !important;
  caret-color: #ffd740 !important;
}
.amber.accent-3 {
  background-color: #ffc400 !important;
  border-color: #ffc400 !important;
}
.amber--text.text--accent-3 {
  color: #ffc400 !important;
  caret-color: #ffc400 !important;
}
.amber.accent-4 {
  background-color: #ffab00 !important;
  border-color: #ffab00 !important;
}
.amber--text.text--accent-4 {
  color: #ffab00 !important;
  caret-color: #ffab00 !important;
}
.orange {
  background-color: #ff9800 !important;
  border-color: #ff9800 !important;
}
.orange--text {
  color: #ff9800 !important;
  caret-color: #ff9800 !important;
}
.orange.lighten-5 {
  background-color: #fff3e0 !important;
  border-color: #fff3e0 !important;
}
.orange--text.text--lighten-5 {
  color: #fff3e0 !important;
  caret-color: #fff3e0 !important;
}
.orange.lighten-4 {
  background-color: #ffe0b2 !important;
  border-color: #ffe0b2 !important;
}
.orange--text.text--lighten-4 {
  color: #ffe0b2 !important;
  caret-color: #ffe0b2 !important;
}
.orange.lighten-3 {
  background-color: #ffcc80 !important;
  border-color: #ffcc80 !important;
}
.orange--text.text--lighten-3 {
  color: #ffcc80 !important;
  caret-color: #ffcc80 !important;
}
.orange.lighten-2 {
  background-color: #ffb74d !important;
  border-color: #ffb74d !important;
}
.orange--text.text--lighten-2 {
  color: #ffb74d !important;
  caret-color: #ffb74d !important;
}
.orange.lighten-1 {
  background-color: #ffa726 !important;
  border-color: #ffa726 !important;
}
.orange--text.text--lighten-1 {
  color: #ffa726 !important;
  caret-color: #ffa726 !important;
}
.orange.darken-1 {
  background-color: #fb8c00 !important;
  border-color: #fb8c00 !important;
}
.orange--text.text--darken-1 {
  color: #fb8c00 !important;
  caret-color: #fb8c00 !important;
}
.orange.darken-2 {
  background-color: #f57c00 !important;
  border-color: #f57c00 !important;
}
.orange--text.text--darken-2 {
  color: #f57c00 !important;
  caret-color: #f57c00 !important;
}
.orange.darken-3 {
  background-color: #ef6c00 !important;
  border-color: #ef6c00 !important;
}
.orange--text.text--darken-3 {
  color: #ef6c00 !important;
  caret-color: #ef6c00 !important;
}
.orange.darken-4 {
  background-color: #e65100 !important;
  border-color: #e65100 !important;
}
.orange--text.text--darken-4 {
  color: #e65100 !important;
  caret-color: #e65100 !important;
}
.orange.accent-1 {
  background-color: #ffd180 !important;
  border-color: #ffd180 !important;
}
.orange--text.text--accent-1 {
  color: #ffd180 !important;
  caret-color: #ffd180 !important;
}
.orange.accent-2 {
  background-color: #ffab40 !important;
  border-color: #ffab40 !important;
}
.orange--text.text--accent-2 {
  color: #ffab40 !important;
  caret-color: #ffab40 !important;
}
.orange.accent-3 {
  background-color: #ff9100 !important;
  border-color: #ff9100 !important;
}
.orange--text.text--accent-3 {
  color: #ff9100 !important;
  caret-color: #ff9100 !important;
}
.orange.accent-4 {
  background-color: #ff6d00 !important;
  border-color: #ff6d00 !important;
}
.orange--text.text--accent-4 {
  color: #ff6d00 !important;
  caret-color: #ff6d00 !important;
}
.deep-orange {
  background-color: #ff5722 !important;
  border-color: #ff5722 !important;
}
.deep-orange--text {
  color: #ff5722 !important;
  caret-color: #ff5722 !important;
}
.deep-orange.lighten-5 {
  background-color: #fbe9e7 !important;
  border-color: #fbe9e7 !important;
}
.deep-orange--text.text--lighten-5 {
  color: #fbe9e7 !important;
  caret-color: #fbe9e7 !important;
}
.deep-orange.lighten-4 {
  background-color: #ffccbc !important;
  border-color: #ffccbc !important;
}
.deep-orange--text.text--lighten-4 {
  color: #ffccbc !important;
  caret-color: #ffccbc !important;
}
.deep-orange.lighten-3 {
  background-color: #ffab91 !important;
  border-color: #ffab91 !important;
}
.deep-orange--text.text--lighten-3 {
  color: #ffab91 !important;
  caret-color: #ffab91 !important;
}
.deep-orange.lighten-2 {
  background-color: #ff8a65 !important;
  border-color: #ff8a65 !important;
}
.deep-orange--text.text--lighten-2 {
  color: #ff8a65 !important;
  caret-color: #ff8a65 !important;
}
.deep-orange.lighten-1 {
  background-color: #ff7043 !important;
  border-color: #ff7043 !important;
}
.deep-orange--text.text--lighten-1 {
  color: #ff7043 !important;
  caret-color: #ff7043 !important;
}
.deep-orange.darken-1 {
  background-color: #f4511e !important;
  border-color: #f4511e !important;
}
.deep-orange--text.text--darken-1 {
  color: #f4511e !important;
  caret-color: #f4511e !important;
}
.deep-orange.darken-2 {
  background-color: #e64a19 !important;
  border-color: #e64a19 !important;
}
.deep-orange--text.text--darken-2 {
  color: #e64a19 !important;
  caret-color: #e64a19 !important;
}
.deep-orange.darken-3 {
  background-color: #d84315 !important;
  border-color: #d84315 !important;
}
.deep-orange--text.text--darken-3 {
  color: #d84315 !important;
  caret-color: #d84315 !important;
}
.deep-orange.darken-4 {
  background-color: #bf360c !important;
  border-color: #bf360c !important;
}
.deep-orange--text.text--darken-4 {
  color: #bf360c !important;
  caret-color: #bf360c !important;
}
.deep-orange.accent-1 {
  background-color: #ff9e80 !important;
  border-color: #ff9e80 !important;
}
.deep-orange--text.text--accent-1 {
  color: #ff9e80 !important;
  caret-color: #ff9e80 !important;
}
.deep-orange.accent-2 {
  background-color: #ff6e40 !important;
  border-color: #ff6e40 !important;
}
.deep-orange--text.text--accent-2 {
  color: #ff6e40 !important;
  caret-color: #ff6e40 !important;
}
.deep-orange.accent-3 {
  background-color: #ff3d00 !important;
  border-color: #ff3d00 !important;
}
.deep-orange--text.text--accent-3 {
  color: #ff3d00 !important;
  caret-color: #ff3d00 !important;
}
.deep-orange.accent-4 {
  background-color: #dd2c00 !important;
  border-color: #dd2c00 !important;
}
.deep-orange--text.text--accent-4 {
  color: #dd2c00 !important;
  caret-color: #dd2c00 !important;
}
.brown {
  background-color: #795548 !important;
  border-color: #795548 !important;
}
.brown--text {
  color: #795548 !important;
  caret-color: #795548 !important;
}
.brown.lighten-5 {
  background-color: #efebe9 !important;
  border-color: #efebe9 !important;
}
.brown--text.text--lighten-5 {
  color: #efebe9 !important;
  caret-color: #efebe9 !important;
}
.brown.lighten-4 {
  background-color: #d7ccc8 !important;
  border-color: #d7ccc8 !important;
}
.brown--text.text--lighten-4 {
  color: #d7ccc8 !important;
  caret-color: #d7ccc8 !important;
}
.brown.lighten-3 {
  background-color: #bcaaa4 !important;
  border-color: #bcaaa4 !important;
}
.brown--text.text--lighten-3 {
  color: #bcaaa4 !important;
  caret-color: #bcaaa4 !important;
}
.brown.lighten-2 {
  background-color: #a1887f !important;
  border-color: #a1887f !important;
}
.brown--text.text--lighten-2 {
  color: #a1887f !important;
  caret-color: #a1887f !important;
}
.brown.lighten-1 {
  background-color: #8d6e63 !important;
  border-color: #8d6e63 !important;
}
.brown--text.text--lighten-1 {
  color: #8d6e63 !important;
  caret-color: #8d6e63 !important;
}
.brown.darken-1 {
  background-color: #6d4c41 !important;
  border-color: #6d4c41 !important;
}
.brown--text.text--darken-1 {
  color: #6d4c41 !important;
  caret-color: #6d4c41 !important;
}
.brown.darken-2 {
  background-color: #5d4037 !important;
  border-color: #5d4037 !important;
}
.brown--text.text--darken-2 {
  color: #5d4037 !important;
  caret-color: #5d4037 !important;
}
.brown.darken-3 {
  background-color: #4e342e !important;
  border-color: #4e342e !important;
}
.brown--text.text--darken-3 {
  color: #4e342e !important;
  caret-color: #4e342e !important;
}
.brown.darken-4 {
  background-color: #3e2723 !important;
  border-color: #3e2723 !important;
}
.brown--text.text--darken-4 {
  color: #3e2723 !important;
  caret-color: #3e2723 !important;
}
.blue-grey {
  background-color: #607d8b !important;
  border-color: #607d8b !important;
}
.blue-grey--text {
  color: #607d8b !important;
  caret-color: #607d8b !important;
}
.blue-grey.lighten-5 {
  background-color: #eceff1 !important;
  border-color: #eceff1 !important;
}
.blue-grey--text.text--lighten-5 {
  color: #eceff1 !important;
  caret-color: #eceff1 !important;
}
.blue-grey.lighten-4 {
  background-color: #cfd8dc !important;
  border-color: #cfd8dc !important;
}
.blue-grey--text.text--lighten-4 {
  color: #cfd8dc !important;
  caret-color: #cfd8dc !important;
}
.blue-grey.lighten-3 {
  background-color: #b0bec5 !important;
  border-color: #b0bec5 !important;
}
.blue-grey--text.text--lighten-3 {
  color: #b0bec5 !important;
  caret-color: #b0bec5 !important;
}
.blue-grey.lighten-2 {
  background-color: #90a4ae !important;
  border-color: #90a4ae !important;
}
.blue-grey--text.text--lighten-2 {
  color: #90a4ae !important;
  caret-color: #90a4ae !important;
}
.blue-grey.lighten-1 {
  background-color: #78909c !important;
  border-color: #78909c !important;
}
.blue-grey--text.text--lighten-1 {
  color: #78909c !important;
  caret-color: #78909c !important;
}
.blue-grey.darken-1 {
  background-color: #546e7a !important;
  border-color: #546e7a !important;
}
.blue-grey--text.text--darken-1 {
  color: #546e7a !important;
  caret-color: #546e7a !important;
}
.blue-grey.darken-2 {
  background-color: #455a64 !important;
  border-color: #455a64 !important;
}
.blue-grey--text.text--darken-2 {
  color: #455a64 !important;
  caret-color: #455a64 !important;
}
.blue-grey.darken-3 {
  background-color: #37474f !important;
  border-color: #37474f !important;
}
.blue-grey--text.text--darken-3 {
  color: #37474f !important;
  caret-color: #37474f !important;
}
.blue-grey.darken-4 {
  background-color: #263238 !important;
  border-color: #263238 !important;
}
.blue-grey--text.text--darken-4 {
  color: #263238 !important;
  caret-color: #263238 !important;
}
.grey {
  background-color: #9e9e9e !important;
  border-color: #9e9e9e !important;
}
.grey--text {
  color: #9e9e9e !important;
  caret-color: #9e9e9e !important;
}
.grey.lighten-5 {
  background-color: #fafafa !important;
  border-color: #fafafa !important;
}
.grey--text.text--lighten-5 {
  color: #fafafa !important;
  caret-color: #fafafa !important;
}
.grey.lighten-4 {
  background-color: #f5f5f5 !important;
  border-color: #f5f5f5 !important;
}
.grey--text.text--lighten-4 {
  color: #f5f5f5 !important;
  caret-color: #f5f5f5 !important;
}
.grey.lighten-3 {
  background-color: #eee !important;
  border-color: #eee !important;
}
.grey--text.text--lighten-3 {
  color: #eee !important;
  caret-color: #eee !important;
}
.grey.lighten-2 {
  background-color: #e0e0e0 !important;
  border-color: #e0e0e0 !important;
}
.grey--text.text--lighten-2 {
  color: #e0e0e0 !important;
  caret-color: #e0e0e0 !important;
}
.grey.lighten-1 {
  background-color: #bdbdbd !important;
  border-color: #bdbdbd !important;
}
.grey--text.text--lighten-1 {
  color: #bdbdbd !important;
  caret-color: #bdbdbd !important;
}
.grey.darken-1 {
  background-color: #757575 !important;
  border-color: #757575 !important;
}
.grey--text.text--darken-1 {
  color: #757575 !important;
  caret-color: #757575 !important;
}
.grey.darken-2 {
  background-color: #616161 !important;
  border-color: #616161 !important;
}
.grey--text.text--darken-2 {
  color: #616161 !important;
  caret-color: #616161 !important;
}
.grey.darken-3 {
  background-color: #424242 !important;
  border-color: #424242 !important;
}
.grey--text.text--darken-3 {
  color: #424242 !important;
  caret-color: #424242 !important;
}
.grey.darken-4 {
  background-color: #212121 !important;
  border-color: #212121 !important;
}
.grey--text.text--darken-4 {
  color: #212121 !important;
  caret-color: #212121 !important;
}
.shades.black {
  background-color: #000 !important;
  border-color: #000 !important;
}
.shades--text.text--black {
  color: #000 !important;
  caret-color: #000 !important;
}
.shades.white {
  background-color: #fff !important;
  border-color: #fff !important;
}
.shades--text.text--white {
  color: #fff !important;
  caret-color: #fff !important;
}
.shades.transparent {
  background-color: transparent !important;
  border-color: transparent !important;
}
.shades--text.text--transparent {
  color: transparent !important;
  caret-color: transparent !important;
}
.elevation-0 {
  box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12) !important;
}
.elevation-1 {
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important;
}
.elevation-2 {
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) !important;
}
.elevation-3 {
  box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12) !important;
}
.elevation-4 {
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12) !important;
}
.elevation-5 {
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12) !important;
}
.elevation-6 {
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12) !important;
}
.elevation-7 {
  box-shadow: 0px 4px 5px -2px rgba(0,0,0,0.2), 0px 7px 10px 1px rgba(0,0,0,0.14), 0px 2px 16px 1px rgba(0,0,0,0.12) !important;
}
.elevation-8 {
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12) !important;
}
.elevation-9 {
  box-shadow: 0px 5px 6px -3px rgba(0,0,0,0.2), 0px 9px 12px 1px rgba(0,0,0,0.14), 0px 3px 16px 2px rgba(0,0,0,0.12) !important;
}
.elevation-10 {
  box-shadow: 0px 6px 6px -3px rgba(0,0,0,0.2), 0px 10px 14px 1px rgba(0,0,0,0.14), 0px 4px 18px 3px rgba(0,0,0,0.12) !important;
}
.elevation-11 {
  box-shadow: 0px 6px 7px -4px rgba(0,0,0,0.2), 0px 11px 15px 1px rgba(0,0,0,0.14), 0px 4px 20px 3px rgba(0,0,0,0.12) !important;
}
.elevation-12 {
  box-shadow: 0px 7px 8px -4px rgba(0,0,0,0.2), 0px 12px 17px 2px rgba(0,0,0,0.14), 0px 5px 22px 4px rgba(0,0,0,0.12) !important;
}
.elevation-13 {
  box-shadow: 0px 7px 8px -4px rgba(0,0,0,0.2), 0px 13px 19px 2px rgba(0,0,0,0.14), 0px 5px 24px 4px rgba(0,0,0,0.12) !important;
}
.elevation-14 {
  box-shadow: 0px 7px 9px -4px rgba(0,0,0,0.2), 0px 14px 21px 2px rgba(0,0,0,0.14), 0px 5px 26px 4px rgba(0,0,0,0.12) !important;
}
.elevation-15 {
  box-shadow: 0px 8px 9px -5px rgba(0,0,0,0.2), 0px 15px 22px 2px rgba(0,0,0,0.14), 0px 6px 28px 5px rgba(0,0,0,0.12) !important;
}
.elevation-16 {
  box-shadow: 0px 8px 10px -5px rgba(0,0,0,0.2), 0px 16px 24px 2px rgba(0,0,0,0.14), 0px 6px 30px 5px rgba(0,0,0,0.12) !important;
}
.elevation-17 {
  box-shadow: 0px 8px 11px -5px rgba(0,0,0,0.2), 0px 17px 26px 2px rgba(0,0,0,0.14), 0px 6px 32px 5px rgba(0,0,0,0.12) !important;
}
.elevation-18 {
  box-shadow: 0px 9px 11px -5px rgba(0,0,0,0.2), 0px 18px 28px 2px rgba(0,0,0,0.14), 0px 7px 34px 6px rgba(0,0,0,0.12) !important;
}
.elevation-19 {
  box-shadow: 0px 9px 12px -6px rgba(0,0,0,0.2), 0px 19px 29px 2px rgba(0,0,0,0.14), 0px 7px 36px 6px rgba(0,0,0,0.12) !important;
}
.elevation-20 {
  box-shadow: 0px 10px 13px -6px rgba(0,0,0,0.2), 0px 20px 31px 3px rgba(0,0,0,0.14), 0px 8px 38px 7px rgba(0,0,0,0.12) !important;
}
.elevation-21 {
  box-shadow: 0px 10px 13px -6px rgba(0,0,0,0.2), 0px 21px 33px 3px rgba(0,0,0,0.14), 0px 8px 40px 7px rgba(0,0,0,0.12) !important;
}
.elevation-22 {
  box-shadow: 0px 10px 14px -6px rgba(0,0,0,0.2), 0px 22px 35px 3px rgba(0,0,0,0.14), 0px 8px 42px 7px rgba(0,0,0,0.12) !important;
}
.elevation-23 {
  box-shadow: 0px 11px 14px -7px rgba(0,0,0,0.2), 0px 23px 36px 3px rgba(0,0,0,0.14), 0px 9px 44px 8px rgba(0,0,0,0.12) !important;
}
.elevation-24 {
  box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12) !important;
}
html {
  box-sizing: border-box;
  overflow-y: scroll; /* All browsers without overlaying scrollbars */
  -webkit-text-size-adjust: 100%; /* iOS 8+ */
}
*,
::before,
::after {
  box-sizing: inherit;
}
::before,
::after {
  text-decoration: inherit; /* Inherit text-decoration and vertical align to ::before and ::after pseudo elements */
  vertical-align: inherit;
}
* {
  background-repeat: no-repeat; /* Set `background-repeat: no-repeat` to all elements */
  padding: 0; /* Reset `padding` and `margin` of all elements */
  margin: 0;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
hr {
  overflow: visible; /* Show the overflow in Edge and IE */
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}
summary {
  display: list-item; /* Add the correct display in all browsers */
}
small {
  font-size: 80%; /* Set font-size to 80% in `small` elements */
}
[hidden],
template {
  display: none; /* Add the correct display in IE */
}
abbr[title] {
  border-bottom: 1px dotted; /* Add a bordered underline effect in all browsers */
  text-decoration: none; /* Remove text decoration in Firefox 40+ */
}
a {
  background-color: transparent; /* Remove the gray background on active links in IE 10 */
  -webkit-text-decoration-skip: objects; /* Remove gaps in links underline in iOS 8+ and Safari 8+ */
}
a:active,
a:hover {
  outline-width: 0; /* Remove the outline when hovering in all browsers */
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace; /* Specify the font family of code elements */
}
b,
strong {
  font-weight: bolder; /* Correct style set to `bold` in Edge 12+, Safari 6.2+, and Chrome 18+ */
}
dfn {
  font-style: italic; /* Address styling not present in Safari and Chrome */
}
mark {
  background-color: #ff0;
  color: #000;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
input {
  border-radius: 0;
}
button,
[type="button"],
[type="reset"],
[type="submit"],
[role="button"] {
  cursor: pointer;
}
[disabled] {
  cursor: default;
}
[type="number"] {
  width: auto; /* Firefox 36+ */
}
[type="search"] {
  -webkit-appearance: textfield; /* Safari 8+ */
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; /* Safari 8 */
}
textarea {
  overflow: auto; /* Internet Explorer 11+ */
  resize: vertical; /* Specify textarea resizability */
}
button,
input,
optgroup,
select,
textarea {
  font: inherit; /* Specify font inheritance of form elements */
}
optgroup {
  font-weight: bold; /* Restore the font weight unset by the previous rule. */
}
button {
  overflow: visible; /* Address `overflow` set to `hidden` in IE 8/9/10/11 */
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: 0;
  padding: 0;
}
button:-moz-focusring,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  outline: 0;
  border: 0;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; /* Correct the inability to style clickable types in iOS */
}
button,
select {
  text-transform: none; /* Firefox 40+, Internet Explorer 11- */
}
button,
input,
select,
textarea {
  background-color: transparent;
  border-style: none;
  color: inherit;
}
select {
  -moz-appearance: none; /* Firefox 36+ */
  -webkit-appearance: none; /* Chrome 41+ */
}
select::-ms-expand {
  display: none; /* Internet Explorer 11+ */
}
select::-ms-value {
  color: currentColor; /* Internet Explorer 11+ */
}
legend {
  border: 0; /* Correct `color` not being inherited in IE 8/9/10/11 */
  color: inherit; /* Correct the color inheritance from `fieldset` elements in IE */
  display: table; /* Correct the text wrapping in Edge and IE */
  max-width: 100%; /* Correct the text wrapping in Edge and IE */
  white-space: normal; /* Correct the text wrapping in Edge and IE */
}
::-webkit-file-upload-button {
  -webkit-appearance: button; /* Correct the inability to style clickable types in iOS and Safari */
  font: inherit; /* Change font properties to `inherit` in Chrome and Safari */
}
[type="search"] {
  -webkit-appearance: textfield; /* Correct the odd appearance in Chrome and Safari */
  outline-offset: -2px; /* Correct the outline style in Safari */
}
img {
  border-style: none; /* Remove border when inside `a` element in IE 8/9/10 */
}
progress {
  vertical-align: baseline;
}
svg:not(:root) {
  overflow: hidden; /* Internet Explorer 11- */
}
audio,
canvas,
progress,
video {
  display: inline-block; /* Internet Explorer 11+, Windows Phone 8.1+ */
}
@media screen {
  [hidden~="screen"] {
    display: inherit;
  }
  [hidden~="screen"]:not(:active):not(:focus):not(:target) {
    position: absolute !important;
    clip: rect(0 0 0 0) !important;
  }
}
[aria-busy="true"] {
  cursor: progress;
}
[aria-controls] {
  cursor: pointer;
}
[aria-disabled] {
  cursor: default;
}
::-moz-selection {
  background-color: #b3d4fc; /* Required when declaring ::selection */
  color: #000;
  text-shadow: none;
}
::selection {
  background-color: #b3d4fc; /* Required when declaring ::selection */
  color: #000;
  text-shadow: none;
}
.bottom-sheet-transition-enter {
  transform: translateY(100%);
}
.bottom-sheet-transition-leave-to {
  transform: translateY(100%);
}
.carousel-transition-enter {
  transform: translate(100%, 0);
}
.carousel-transition-leave,
.carousel-transition-leave-to {
  position: absolute;
  top: 0;
  transform: translate(-100%, 0);
}
.carousel-reverse-transition-enter {
  transform: translate(-100%, 0);
}
.carousel-reverse-transition-leave,
.carousel-reverse-transition-leave-to {
  position: absolute;
  top: 0;
  transform: translate(100%, 0);
}
.dialog-transition-enter,
.dialog-transition-leave-to {
  transform: scale(0.5);
  opacity: 0;
}
.dialog-transition-enter-to,
.dialog-transition-leave {
  opacity: 1;
}
.dialog-bottom-transition-enter,
.dialog-bottom-transition-leave-to {
  transform: translateY(100%);
}
.picker-transition-enter-active,
.picker-reverse-transition-enter-active,
.picker-transition-leave-active,
.picker-reverse-transition-leave-active {
  transition: 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.picker-transition-enter,
.picker-reverse-transition-enter,
.picker-transition-leave-to,
.picker-reverse-transition-leave-to {
  opacity: 0;
}
.picker-transition-leave,
.picker-reverse-transition-leave,
.picker-transition-leave-active,
.picker-reverse-transition-leave-active,
.picker-transition-leave-to,
.picker-reverse-transition-leave-to {
  position: absolute !important;
}
.picker-transition-enter {
  transform: translate(0, 100%);
}
.picker-transition-leave-to {
  transform: translate(0, -100%);
}
.picker-reverse-transition-enter {
  transform: translate(0, -100%);
}
.picker-reverse-transition-leave-to {
  transform: translate(0, 100%);
}
.picker-title-transition-enter-to,
.picker-title-transition-leave {
  transform: translate(0, 0);
}
.picker-title-transition-enter {
  transform: translate(-100%, 0);
}
.picker-title-transition-leave-to {
  opacity: 0;
  transform: translate(100%, 0);
}
.picker-title-transition-leave,
.picker-title-transition-leave-to,
.picker-title-transition-leave-active {
  position: absolute !important;
}
.tab-transition-enter {
  transform: translate(100%, 0);
}
.tab-transition-leave,
.tab-transition-leave-active {
  position: absolute;
  top: 0;
}
.tab-transition-leave-to {
  position: absolute;
  transform: translate(-100%, 0);
}
.tab-reverse-transition-enter {
  transform: translate(-100%, 0);
}
.tab-reverse-transition-leave,
.tab-reverse-transition-leave-to {
  top: 0;
  position: absolute;
  transform: translate(100%, 0);
}
.expand-transition-enter-active,
.expand-transition-leave-active {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.expand-transition-move {
  transition: transform 0.6s;
}
.expand-x-transition-enter-active,
.expand-x-transition-leave-active {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.expand-x-transition-move {
  transition: transform 0.6s;
}
.scale-transition-enter-active,
.scale-transition-leave-active {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.scale-transition-move {
  transition: transform 0.6s;
}
.scale-transition-enter,
.scale-transition-leave,
.scale-transition-leave-to {
  opacity: 0;
  transform: scale(0);
}
.message-transition-enter-active,
.message-transition-leave-active {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.message-transition-move {
  transition: transform 0.6s;
}
.message-transition-enter,
.message-transition-leave-to {
  opacity: 0;
  transform: translateY(-15px);
}
.message-transition-leave,
.message-transition-leave-active {
  position: absolute;
}
.slide-y-transition-enter-active,
.slide-y-transition-leave-active {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.slide-y-transition-move {
  transition: transform 0.6s;
}
.slide-y-transition-enter,
.slide-y-transition-leave-to {
  opacity: 0;
  transform: translateY(-15px);
}
.slide-y-reverse-transition-enter-active,
.slide-y-reverse-transition-leave-active {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.slide-y-reverse-transition-move {
  transition: transform 0.6s;
}
.slide-y-reverse-transition-enter,
.slide-y-reverse-transition-leave-to {
  opacity: 0;
  transform: translateY(15px);
}
.scroll-y-transition-enter-active,
.scroll-y-transition-leave-active {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.scroll-y-transition-move {
  transition: transform 0.6s;
}
.scroll-y-transition-enter,
.scroll-y-transition-leave-to {
  opacity: 0;
}
.scroll-y-transition-enter {
  transform: translateY(-15px);
}
.scroll-y-transition-leave-to {
  transform: translateY(15px);
}
.scroll-y-reverse-transition-enter-active,
.scroll-y-reverse-transition-leave-active {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.scroll-y-reverse-transition-move {
  transition: transform 0.6s;
}
.scroll-y-reverse-transition-enter,
.scroll-y-reverse-transition-leave-to {
  opacity: 0;
}
.scroll-y-reverse-transition-enter {
  transform: translateY(15px);
}
.scroll-y-reverse-transition-leave-to {
  transform: translateY(-15px);
}
.scroll-x-transition-enter-active,
.scroll-x-transition-leave-active {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.scroll-x-transition-move {
  transition: transform 0.6s;
}
.scroll-x-transition-enter,
.scroll-x-transition-leave-to {
  opacity: 0;
}
.scroll-x-transition-enter {
  transform: translateX(-15px);
}
.scroll-x-transition-leave-to {
  transform: translateX(15px);
}
.scroll-x-reverse-transition-enter-active,
.scroll-x-reverse-transition-leave-active {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.scroll-x-reverse-transition-move {
  transition: transform 0.6s;
}
.scroll-x-reverse-transition-enter,
.scroll-x-reverse-transition-leave-to {
  opacity: 0;
}
.scroll-x-reverse-transition-enter {
  transform: translateX(15px);
}
.scroll-x-reverse-transition-leave-to {
  transform: translateX(-15px);
}
.slide-x-transition-enter-active,
.slide-x-transition-leave-active {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.slide-x-transition-move {
  transition: transform 0.6s;
}
.slide-x-transition-enter,
.slide-x-transition-leave-to {
  opacity: 0;
  transform: translateX(-15px);
}
.slide-x-reverse-transition-enter-active,
.slide-x-reverse-transition-leave-active {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.slide-x-reverse-transition-move {
  transition: transform 0.6s;
}
.slide-x-reverse-transition-enter,
.slide-x-reverse-transition-leave-to {
  opacity: 0;
  transform: translateX(15px);
}
.fade-transition-enter-active,
.fade-transition-leave-active {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.fade-transition-move {
  transition: transform 0.6s;
}
.fade-transition-enter,
.fade-transition-leave-to {
  opacity: 0;
}
.fab-transition-enter-active,
.fab-transition-leave-active {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.fab-transition-move {
  transition: transform 0.6s;
}
.fab-transition-enter,
.fab-transition-leave-to {
  transform: scale(0) rotate(-45deg);
}
.blockquote {
  padding: 16px 0 16px 24px;
  font-size: 18px;
  font-weight: 300;
}
code,
kbd {
  display: inline-block;
  border-radius: 3px;
  white-space: pre-wrap;
  font-size: 85%;
  font-weight: 900;
}
code:after,
kbd:after,
code:before,
kbd:before {
  content: "\00a0";
  letter-spacing: -1px;
}
code {
  background-color: #f5f5f5;
  color: #bd4147;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}
kbd {
  background: #616161;
  color: #fff;
}
html {
  font-size: 14px;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.application {
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
}
::-ms-clear,
::-ms-reveal {
  display: none;
}
ul,
ol {
  padding-left: 24px;
}
.display-4 {
  font-size: 112px !important;
  font-weight: 300;
  line-height: 1 !important;
  letter-spacing: -0.04em !important;
  font-family: 'Roboto', sans-serif !important;
}
.display-3 {
  font-size: 56px !important;
  font-weight: 400;
  line-height: 1.35 !important;
  letter-spacing: -0.02em !important;
  font-family: 'Roboto', sans-serif !important;
}
.display-2 {
  font-size: 45px !important;
  font-weight: 400;
  line-height: 48px !important;
  letter-spacing: normal !important;
  font-family: 'Roboto', sans-serif !important;
}
.display-1 {
  font-size: 34px !important;
  font-weight: 400;
  line-height: 40px !important;
  letter-spacing: normal !important;
  font-family: 'Roboto', sans-serif !important;
}
.headline {
  font-size: 24px !important;
  font-weight: 400;
  line-height: 32px !important;
  letter-spacing: normal !important;
  font-family: 'Roboto', sans-serif !important;
}
.title {
  font-size: 20px !important;
  font-weight: 500;
  line-height: 1 !important;
  letter-spacing: 0.02em !important;
  font-family: 'Roboto', sans-serif !important;
}
.subheading {
  font-size: 16px !important;
  font-weight: 400;
}
.body-2 {
  font-size: 14px !important;
  font-weight: 500;
}
.body-1 {
  font-size: 14px !important;
  font-weight: 400;
}
.caption {
  font-size: 12px !important;
  font-weight: 400;
}
p {
  margin-bottom: 16px;
}
@media only print {
  .hidden-print-only {
    display: none !important;
  }
}
@media only screen {
  .hidden-screen-only {
    display: none !important;
  }
}
@media only screen and (max-width: 599px) {
  .hidden-xs-only {
    display: none !important;
  }
}
@media only screen and (min-width: 600px) and (max-width: 959px) {
  .hidden-sm-only {
    display: none !important;
  }
}
@media only screen and (max-width: 959px) {
  .hidden-sm-and-down {
    display: none !important;
  }
}
@media only screen and (min-width: 600px) {
  .hidden-sm-and-up {
    display: none !important;
  }
}
@media only screen and (min-width: 960px) and (max-width: 1263px) {
  .hidden-md-only {
    display: none !important;
  }
}
@media only screen and (max-width: 1263px) {
  .hidden-md-and-down {
    display: none !important;
  }
}
@media only screen and (min-width: 960px) {
  .hidden-md-and-up {
    display: none !important;
  }
}
@media only screen and (min-width: 1264px) and (max-width: 1903px) {
  .hidden-lg-only {
    display: none !important;
  }
}
@media only screen and (max-width: 1903px) {
  .hidden-lg-and-down {
    display: none !important;
  }
}
@media only screen and (min-width: 1264px) {
  .hidden-lg-and-up {
    display: none !important;
  }
}
@media only screen and (min-width: 1904px) {
  .hidden-xl-only {
    display: none !important;
  }
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.overflow-y-hidden {
  overflow-y: hidden;
}
.right {
  float: right !important;
}
.left {
  float: left !important;
}
.ma-auto {
  margin-top: auto !important;
  margin-right: auto !important;
  margin-bottom: auto !important;
  margin-left: auto !important;
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.mt-auto {
  margin-top: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.ml-auto {
  margin-left: auto !important;
}
.ma-0 {
  margin: 0 0 !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.pa-0 {
  padding: 0 0 !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.ma-1 {
  margin: 4px 4px !important;
}
.my-1 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}
.mx-1 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}
.mt-1 {
  margin-top: 4px !important;
}
.mr-1 {
  margin-right: 4px !important;
}
.mb-1 {
  margin-bottom: 4px !important;
}
.ml-1 {
  margin-left: 4px !important;
}
.pa-1 {
  padding: 4px 4px !important;
}
.py-1 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.px-1 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}
.pt-1 {
  padding-top: 4px !important;
}
.pr-1 {
  padding-right: 4px !important;
}
.pb-1 {
  padding-bottom: 4px !important;
}
.pl-1 {
  padding-left: 4px !important;
}
.ma-2 {
  margin: 8px 8px !important;
}
.my-2 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
.mx-2 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}
.mt-2 {
  margin-top: 8px !important;
}
.mr-2 {
  margin-right: 8px !important;
}
.mb-2 {
  margin-bottom: 8px !important;
}
.ml-2 {
  margin-left: 8px !important;
}
.pa-2 {
  padding: 8px 8px !important;
}
.py-2 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.px-2 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.pt-2 {
  padding-top: 8px !important;
}
.pr-2 {
  padding-right: 8px !important;
}
.pb-2 {
  padding-bottom: 8px !important;
}
.pl-2 {
  padding-left: 8px !important;
}
.ma-3 {
  margin: 16px 16px !important;
}
.my-3 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}
.mx-3 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}
.mt-3 {
  margin-top: 16px !important;
}
.mr-3 {
  margin-right: 16px !important;
}
.mb-3 {
  margin-bottom: 16px !important;
}
.ml-3 {
  margin-left: 16px !important;
}
.pa-3 {
  padding: 16px 16px !important;
}
.py-3 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}
.px-3 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.pt-3 {
  padding-top: 16px !important;
}
.pr-3 {
  padding-right: 16px !important;
}
.pb-3 {
  padding-bottom: 16px !important;
}
.pl-3 {
  padding-left: 16px !important;
}
.ma-4 {
  margin: 24px 24px !important;
}
.my-4 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}
.mx-4 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}
.mt-4 {
  margin-top: 24px !important;
}
.mr-4 {
  margin-right: 24px !important;
}
.mb-4 {
  margin-bottom: 24px !important;
}
.ml-4 {
  margin-left: 24px !important;
}
.pa-4 {
  padding: 24px 24px !important;
}
.py-4 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}
.px-4 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}
.pt-4 {
  padding-top: 24px !important;
}
.pr-4 {
  padding-right: 24px !important;
}
.pb-4 {
  padding-bottom: 24px !important;
}
.pl-4 {
  padding-left: 24px !important;
}
.ma-5 {
  margin: 48px 48px !important;
}
.my-5 {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}
.mx-5 {
  margin-left: 48px !important;
  margin-right: 48px !important;
}
.mt-5 {
  margin-top: 48px !important;
}
.mr-5 {
  margin-right: 48px !important;
}
.mb-5 {
  margin-bottom: 48px !important;
}
.ml-5 {
  margin-left: 48px !important;
}
.pa-5 {
  padding: 48px 48px !important;
}
.py-5 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}
.px-5 {
  padding-left: 48px !important;
  padding-right: 48px !important;
}
.pt-5 {
  padding-top: 48px !important;
}
.pr-5 {
  padding-right: 48px !important;
}
.pb-5 {
  padding-bottom: 48px !important;
}
.pl-5 {
  padding-left: 48px !important;
}
@media all and (min-width: 0) {
  .text-xs-left {
    text-align: left !important;
  }
  .text-xs-center {
    text-align: center !important;
  }
  .text-xs-right {
    text-align: right !important;
  }
  .text-xs-justify {
    text-align: justify !important;
  }
}
@media all and (min-width: 600px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-justify {
    text-align: justify !important;
  }
}
@media all and (min-width: 960px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-center {
    text-align: center !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-justify {
    text-align: justify !important;
  }
}
@media all and (min-width: 1264px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-justify {
    text-align: justify !important;
  }
}
@media all and (min-width: 1904px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-justify {
    text-align: justify !important;
  }
}
.font-weight-thin {
  font-weight: 100 !important;
}
.font-weight-light {
  font-weight: 300 !important;
}
.font-weight-regular {
  font-weight: 400 !important;
}
.font-weight-medium {
  font-weight: 500 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.font-weight-black {
  font-weight: 900 !important;
}
.font-italic {
  font-style: italic !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-none {
  text-transform: none !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-no-wrap {
  white-space: nowrap !important;
}
.text-truncate {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  line-height: 1.1 !important;
}
.transition-fast-out-slow-in {
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1) !important;
}
.transition-linear-out-slow-in {
  transition: 0.3s cubic-bezier(0, 0, 0.2, 1) !important;
}
.transition-fast-out-linear-in {
  transition: 0.3s cubic-bezier(0.4, 0, 1, 1) !important;
}
.transition-ease-in-out {
  transition: 0.3s cubic-bezier(0.4, 0, 0.6, 1) !important;
}
.transition-fast-in-fast-out {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
}
.transition-swing {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
}
/*# sourceMappingURL=node_modules/vuetify/src/stylus/app.css.map */