.theme--light.v-footer {
  background: #f5f5f5;
  color: rgba(0,0,0,0.87);
}
.theme--dark.v-footer {
  background: #212121;
  color: #fff;
}
.v-footer {
  align-items: center;
  display: flex;
  flex: 0 1 auto !important;
  min-height: 36px;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.v-footer--absolute,
.v-footer--fixed {
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 3;
}
.v-footer--inset {
  z-index: 2;
}
.v-footer--absolute {
  position: absolute;
}
.v-footer--fixed {
  position: fixed;
}
/*# sourceMappingURL=node_modules/vuetify/src/stylus/components/_footer.css.map */