/** Themes */
.theme--light.v-btn-toggle {
  background: #fff;
}
.theme--light.v-btn-toggle .v-btn {
  color: rgba(0,0,0,0.87);
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--only-child) .v-btn.v-btn--active:not(:last-child) {
  border-right-color: rgba(0,0,0,0.26);
}
.theme--dark.v-btn-toggle {
  background: #424242;
}
.theme--dark.v-btn-toggle .v-btn {
  color: #fff;
}
.theme--dark.v-btn-toggle:not(.v-btn-toggle--only-child) .v-btn.v-btn--active:not(:last-child) {
  border-right-color: rgba(255,255,255,0.3);
}
.v-btn-toggle {
  display: inline-flex;
  border-radius: 2px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  will-change: background, box-shadow;
}
.v-btn-toggle .v-btn {
  justify-content: center;
  min-width: auto;
  width: auto;
  padding: 0 8px;
  margin: 0;
  opacity: 0.4;
  border-radius: 0;
}
.v-btn-toggle .v-btn:not(:last-child) {
  border-right: 1px solid transparent;
}
.v-btn-toggle .v-btn:after {
  display: none;
}
.v-btn-toggle .v-btn.v-btn--active {
  opacity: 1;
}
.v-btn-toggle .v-btn span + .v-icon {
  font-size: initial;
  margin-left: 10px;
}
.v-btn-toggle .v-btn:first-child {
  border-radius: 2px 0 0 2px;
}
.v-btn-toggle .v-btn:last-child {
  border-radius: 0 2px 2px 0;
}
.v-btn-toggle--selected {
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}
/*# sourceMappingURL=node_modules/vuetify/src/stylus/components/_button-toggle.css.map */