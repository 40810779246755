.v-window__container {
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-window__container--is-active {
  overflow: hidden;
}
.v-window-x-transition-enter-active,
.v-window-x-reverse-transition-enter-active,
.v-window-y-transition-enter-active,
.v-window-y-reverse-transition-enter-active,
.v-window-x-transition-leave-active,
.v-window-x-reverse-transition-leave-active,
.v-window-y-transition-leave-active,
.v-window-y-reverse-transition-leave-active {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-window-x-transition-leave,
.v-window-x-reverse-transition-leave,
.v-window-y-transition-leave,
.v-window-y-reverse-transition-leave,
.v-window-x-transition-leave-to,
.v-window-x-reverse-transition-leave-to,
.v-window-y-transition-leave-to,
.v-window-y-reverse-transition-leave-to {
  position: absolute !important;
  top: 0;
  width: 100%;
}
.v-window-x-transition-enter {
  transform: translateX(100%);
}
.v-window-x-transition-leave-to {
  transform: translateX(-100%);
}
.v-window-x-reverse-transition-enter {
  transform: translateX(-100%);
}
.v-window-x-reverse-transition-leave-to {
  transform: translateX(100%);
}
.v-window-y-transition-enter {
  transform: translateY(100%);
}
.v-window-y-transition-leave-to {
  transform: translateY(-100%);
}
.v-window-y-reverse-transition-enter {
  transform: translateY(-100%);
}
.v-window-y-reverse-transition-leave-to {
  transform: translateY(100%);
}
/*# sourceMappingURL=node_modules/vuetify/src/stylus/components/_windows.css.map */