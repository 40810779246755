/* Theme */
.theme--light.v-tabs__bar {
  background-color: #fff;
}
.theme--light.v-tabs__bar .v-tabs__div {
  color: rgba(0,0,0,0.87);
}
.theme--light.v-tabs__bar .v-tabs__item--disabled {
  color: rgba(0,0,0,0.26);
}
.theme--dark.v-tabs__bar {
  background-color: #424242;
}
.theme--dark.v-tabs__bar .v-tabs__div {
  color: #fff;
}
.theme--dark.v-tabs__bar .v-tabs__item--disabled {
  color: rgba(255,255,255,0.3);
}
.v-tabs {
  position: relative;
}
.v-tabs__bar {
  position: relative;
  border-radius: inherit;
}
.v-tabs__icon {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  height: 100%;
  position: absolute;
  top: 0;
  user-select: none;
  width: 32px;
}
.v-tabs__icon--prev {
  left: 4px;
}
.v-tabs__icon--next {
  right: 4px;
}
.v-tabs__wrapper {
  overflow: hidden;
  contain: content;
  display: flex;
}
.v-tabs__wrapper--show-arrows {
  margin-left: 40px;
  margin-right: 40px;
}
.v-tabs__wrapper--show-arrows .v-tabs__container--align-with-title {
  padding-left: 16px;
}
@media only screen and (max-width: 599px) {
  .v-tabs__wrapper--show-arrows .v-tabs__container--align-with-title {
    padding-left: 24px;
  }
}
.v-tabs__container {
  flex: 1 0 auto;
  display: flex;
  height: 48px;
  list-style-type: none;
  transition: transform 0.6s cubic-bezier(0.86, 0, 0.07, 1);
  white-space: nowrap;
  position: relative;
}
.v-tabs__container--overflow .v-tabs__div {
  flex: 1 0 auto;
}
.v-tabs__container--grow .v-tabs__div {
  flex: 1 0 auto;
  max-width: none;
}
.v-tabs__container--icons-and-text {
  height: 72px;
}
.v-tabs__container--align-with-title {
  padding-left: 56px;
}
.v-tabs__container--fixed-tabs .v-tabs__div,
.v-tabs__container--icons-and-text .v-tabs__div {
  min-width: 72px;
}
@media only screen and (min-width: 600px) {
  .v-tabs__container--fixed-tabs .v-tabs__div,
  .v-tabs__container--icons-and-text .v-tabs__div {
    min-width: 160px;
  }
}
@media only screen and (max-width: 599px) {
  .v-tabs__container--fixed-tabs .v-tabs__div {
    flex: 1 0 auto;
  }
}
.v-tabs__container--centered > .v-tabs__div:first-child,
.v-tabs__container--fixed-tabs > .v-tabs__div:first-child,
.v-tabs__container--right > .v-tabs__div:first-child {
  margin-left: auto;
}
.v-tabs__container--centered .v-tabs__slider-wrapper + .v-tabs__div,
.v-tabs__container--fixed-tabs .v-tabs__slider-wrapper + .v-tabs__div,
.v-tabs__container--right .v-tabs__slider-wrapper + .v-tabs__div {
  margin-left: auto;
}
.v-tabs__container--centered > .v-tabs__div:last-child,
.v-tabs__container--fixed-tabs > .v-tabs__div:last-child {
  margin-right: auto;
}
.v-tabs__container--icons-and-text .v-tabs__item {
  flex-direction: column-reverse;
}
.v-tabs__container--icons-and-text .v-tabs__item .v-icon {
  margin-bottom: 6px;
}
.v-tabs__div {
  align-items: center;
  display: inline-flex;
  flex: 0 1 auto;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  height: inherit;
  max-width: 264px;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
}
.v-tabs__item {
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  justify-content: center;
  max-width: inherit;
  padding: 6px 12px;
  text-decoration: none;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  user-select: none;
  white-space: normal;
}
.v-tabs__item:not(.v-tabs__item--active) {
  opacity: 0.7;
}
.v-tabs__item--disabled {
  pointer-events: none;
}
.v-tabs__slider {
  height: 2px;
  width: 100%;
}
.v-tabs__slider-wrapper {
  bottom: 0;
  margin: 0 !important;
  position: absolute;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
/*# sourceMappingURL=node_modules/vuetify/src/stylus/components/_tabs.css.map */