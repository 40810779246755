.v-content {
  transition: none;
  display: flex;
  flex: 1 0 auto;
  max-width: 100%;
}
.v-content[data-booted="true"] {
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.v-content__wrap {
  flex: 1 1 auto;
  max-width: 100%;
  position: relative;
}
@-moz-document url-prefix() {
@media print {
    .v-content {
      display: block;
    }
}
}
/*# sourceMappingURL=node_modules/vuetify/src/stylus/components/_content.css.map */