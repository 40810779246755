.v-dialog {
  box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
  border-radius: 2px;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  z-index: inherit;
}
.v-dialog__content {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  z-index: 6;
  outline: none;
}
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 90%;
}
.v-dialog__activator {
  cursor: pointer;
}
.v-dialog__activator * {
  cursor: pointer;
}
.v-dialog__container {
  display: inline-block;
  vertical-align: middle;
}
.v-dialog--animated {
  animation-duration: 0.15s;
  animation-name: animate-dialog;
  animation-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
}
.v-dialog--fullscreen {
  border-radius: 0;
  margin: 0;
  height: 100%;
  position: fixed;
  overflow-y: auto;
  top: 0;
  left: 0;
}
.v-dialog--fullscreen > .v-card {
  min-height: 100%;
  min-width: 100%;
  margin: 0 !important;
  padding: 0 !important;
}
.v-dialog--scrollable,
.v-dialog--scrollable > form {
  display: flex;
}
.v-dialog--scrollable > .v-card,
.v-dialog--scrollable > form > .v-card {
  display: flex;
  flex: 1 1 100%;
  max-width: 100%;
  flex-direction: column;
}
.v-dialog--scrollable > .v-card > .v-card__title,
.v-dialog--scrollable > form > .v-card > .v-card__title,
.v-dialog--scrollable > .v-card > .v-card__actions,
.v-dialog--scrollable > form > .v-card > .v-card__actions {
  flex: 1 0 auto;
}
.v-dialog--scrollable > .v-card > .v-card__text,
.v-dialog--scrollable > form > .v-card > .v-card__text {
  overflow-y: auto;
  backface-visibility: hidden;
}
@-moz-keyframes animate-dialog {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes animate-dialog {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}
@-o-keyframes animate-dialog {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes animate-dialog {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}
/*# sourceMappingURL=node_modules/vuetify/src/stylus/components/_dialogs.css.map */