/** Theme */
.theme--light.v-input--selection-controls.v-input--is-disabled .v-icon {
  color: rgba(0,0,0,0.26) !important;
}
.theme--dark.v-input--selection-controls.v-input--is-disabled .v-icon {
  color: rgba(255,255,255,0.3) !important;
}
.application--is-rtl .v-input--selection-controls .v-input--selection-controls__input {
  margin-right: 0;
  margin-left: 8px;
}
.v-input--selection-controls {
  margin-top: 16px;
  padding-top: 4px;
}
.v-input--selection-controls .v-input__append-outer,
.v-input--selection-controls .v-input__prepend-outer {
  margin-top: 0;
  margin-bottom: 0;
}
.v-input--selection-controls .v-input__control {
  flex-grow: 0;
  width: auto;
}
.v-input--selection-controls:not(.v-input--hide-details) .v-input__slot {
  margin-bottom: 12px;
}
.v-input--selection-controls__input {
  color: inherit;
  display: inline-flex;
  flex: 0 0 auto;
  height: 24px;
  position: relative;
  margin-right: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: color, transform;
  width: 24px;
  user-select: none;
}
.v-input--selection-controls__input input {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  user-select: none;
}
.v-input--selection-controls__input + .v-label {
  cursor: pointer;
  user-select: none;
}
.v-input--selection-controls__ripple {
  border-radius: 50%;
  cursor: pointer;
  height: 34px;
  position: absolute;
  transition: inherit;
  width: 34px;
  left: -12px;
  top: calc(50% - 24px);
  margin: 7px;
}
.v-input--selection-controls__ripple:before {
  border-radius: inherit;
  bottom: 0;
  content: '';
  position: absolute;
  opacity: 0.2;
  left: 0;
  right: 0;
  top: 0;
  transform-origin: center center;
  transform: scale(0.2);
  transition: inherit;
}
.v-input--selection-controls__ripple .v-ripple__container {
  transform: scale(1.4);
}
.v-input--selection-controls.v-input .v-label {
  align-items: center;
  display: inline-flex;
  top: 0;
  height: auto;
}
.v-input--selection-controls.v-input--is-focused .v-input--selection-controls__ripple:before,
.v-input--selection-controls .v-radio--is-focused .v-input--selection-controls__ripple:before {
  background: currentColor;
  transform: scale(0.8);
}
/*# sourceMappingURL=node_modules/vuetify/src/stylus/components/_selection-controls.css.map */