.v-input--radio-group__input {
  display: flex;
  width: 100%;
}
.v-input--radio-group--column .v-input--radio-group__input > .v-label {
  padding-bottom: 8px;
}
.v-input--radio-group--row .v-input--radio-group__input > .v-label {
  padding-right: 8px;
}
.v-input--radio-group--row .v-input--radio-group__input {
  flex-direction: row;
  flex-wrap: wrap;
}
.v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
  margin-bottom: 8px;
}
.v-input--radio-group--column .v-input--radio-group__input {
  flex-direction: column;
}
/*# sourceMappingURL=node_modules/vuetify/src/stylus/components/_radio-group.css.map */