.container {
  flex: 1 1 100%;
  margin: auto;
  padding: 24px;
  width: 100%;
}
@media only screen and (min-width: 960px) {
  .container {
    max-width: 900px;
  }
}
@media only screen and (min-width: 1264px) {
  .container {
    max-width: 1185px;
  }
}
@media only screen and (min-width: 1904px) {
  .container {
    max-width: 1785px;
  }
}
@media only screen and (max-width: 959px) {
  .container {
    padding: 16px;
  }
}
.container.fluid {
  max-width: 100%;
}
.container.fill-height {
  align-items: center;
  display: flex;
}
.container.fill-height > .layout {
  height: 100%;
  flex: 1 1 auto;
}
.container.grid-list-xs .layout .flex {
  padding: 1px;
}
.container.grid-list-xs .layout:only-child {
  margin: -1px;
}
.container.grid-list-xs .layout:not(:only-child) {
  margin: auto -1px;
}
.container.grid-list-xs *:not(:only-child) .layout:first-child {
  margin-top: -1px;
}
.container.grid-list-xs *:not(:only-child) .layout:last-child {
  margin-bottom: -1px;
}
.container.grid-list-sm .layout .flex {
  padding: 2px;
}
.container.grid-list-sm .layout:only-child {
  margin: -2px;
}
.container.grid-list-sm .layout:not(:only-child) {
  margin: auto -2px;
}
.container.grid-list-sm *:not(:only-child) .layout:first-child {
  margin-top: -2px;
}
.container.grid-list-sm *:not(:only-child) .layout:last-child {
  margin-bottom: -2px;
}
.container.grid-list-md .layout .flex {
  padding: 4px;
}
.container.grid-list-md .layout:only-child {
  margin: -4px;
}
.container.grid-list-md .layout:not(:only-child) {
  margin: auto -4px;
}
.container.grid-list-md *:not(:only-child) .layout:first-child {
  margin-top: -4px;
}
.container.grid-list-md *:not(:only-child) .layout:last-child {
  margin-bottom: -4px;
}
.container.grid-list-lg .layout .flex {
  padding: 8px;
}
.container.grid-list-lg .layout:only-child {
  margin: -8px;
}
.container.grid-list-lg .layout:not(:only-child) {
  margin: auto -8px;
}
.container.grid-list-lg *:not(:only-child) .layout:first-child {
  margin-top: -8px;
}
.container.grid-list-lg *:not(:only-child) .layout:last-child {
  margin-bottom: -8px;
}
.container.grid-list-xl .layout .flex {
  padding: 12px;
}
.container.grid-list-xl .layout:only-child {
  margin: -12px;
}
.container.grid-list-xl .layout:not(:only-child) {
  margin: auto -12px;
}
.container.grid-list-xl *:not(:only-child) .layout:first-child {
  margin-top: -12px;
}
.container.grid-list-xl *:not(:only-child) .layout:last-child {
  margin-bottom: -12px;
}
.layout {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: nowrap;
  min-width: 0;
}
.layout.row {
  flex-direction: row;
}
.layout.row.reverse {
  flex-direction: row-reverse;
}
.layout.column {
  flex-direction: column;
}
.layout.column.reverse {
  flex-direction: column-reverse;
}
.layout.column > .flex {
  max-width: 100%;
}
.layout.wrap {
  flex-wrap: wrap;
}
@media all and (min-width: 0) {
  .flex.xs1 {
    flex-basis: 8.333333333333332%;
    flex-grow: 0;
    max-width: 8.333333333333332%;
  }
  .flex.order-xs1 {
    order: 1;
  }
  .flex.xs2 {
    flex-basis: 16.666666666666664%;
    flex-grow: 0;
    max-width: 16.666666666666664%;
  }
  .flex.order-xs2 {
    order: 2;
  }
  .flex.xs3 {
    flex-basis: 25%;
    flex-grow: 0;
    max-width: 25%;
  }
  .flex.order-xs3 {
    order: 3;
  }
  .flex.xs4 {
    flex-basis: 33.33333333333333%;
    flex-grow: 0;
    max-width: 33.33333333333333%;
  }
  .flex.order-xs4 {
    order: 4;
  }
  .flex.xs5 {
    flex-basis: 41.66666666666667%;
    flex-grow: 0;
    max-width: 41.66666666666667%;
  }
  .flex.order-xs5 {
    order: 5;
  }
  .flex.xs6 {
    flex-basis: 50%;
    flex-grow: 0;
    max-width: 50%;
  }
  .flex.order-xs6 {
    order: 6;
  }
  .flex.xs7 {
    flex-basis: 58.333333333333336%;
    flex-grow: 0;
    max-width: 58.333333333333336%;
  }
  .flex.order-xs7 {
    order: 7;
  }
  .flex.xs8 {
    flex-basis: 66.66666666666666%;
    flex-grow: 0;
    max-width: 66.66666666666666%;
  }
  .flex.order-xs8 {
    order: 8;
  }
  .flex.xs9 {
    flex-basis: 75%;
    flex-grow: 0;
    max-width: 75%;
  }
  .flex.order-xs9 {
    order: 9;
  }
  .flex.xs10 {
    flex-basis: 83.33333333333334%;
    flex-grow: 0;
    max-width: 83.33333333333334%;
  }
  .flex.order-xs10 {
    order: 10;
  }
  .flex.xs11 {
    flex-basis: 91.66666666666666%;
    flex-grow: 0;
    max-width: 91.66666666666666%;
  }
  .flex.order-xs11 {
    order: 11;
  }
  .flex.xs12 {
    flex-basis: 100%;
    flex-grow: 0;
    max-width: 100%;
  }
  .flex.order-xs12 {
    order: 12;
  }
  .flex.offset-xs0 {
    margin-left: 0%;
  }
  .flex.offset-xs1 {
    margin-left: 8.333333333333332%;
  }
  .flex.offset-xs2 {
    margin-left: 16.666666666666664%;
  }
  .flex.offset-xs3 {
    margin-left: 25%;
  }
  .flex.offset-xs4 {
    margin-left: 33.33333333333333%;
  }
  .flex.offset-xs5 {
    margin-left: 41.66666666666667%;
  }
  .flex.offset-xs6 {
    margin-left: 50%;
  }
  .flex.offset-xs7 {
    margin-left: 58.333333333333336%;
  }
  .flex.offset-xs8 {
    margin-left: 66.66666666666666%;
  }
  .flex.offset-xs9 {
    margin-left: 75%;
  }
  .flex.offset-xs10 {
    margin-left: 83.33333333333334%;
  }
  .flex.offset-xs11 {
    margin-left: 91.66666666666666%;
  }
  .flex.offset-xs12 {
    margin-left: 100%;
  }
}
@media all and (min-width: 600px) {
  .flex.sm1 {
    flex-basis: 8.333333333333332%;
    flex-grow: 0;
    max-width: 8.333333333333332%;
  }
  .flex.order-sm1 {
    order: 1;
  }
  .flex.sm2 {
    flex-basis: 16.666666666666664%;
    flex-grow: 0;
    max-width: 16.666666666666664%;
  }
  .flex.order-sm2 {
    order: 2;
  }
  .flex.sm3 {
    flex-basis: 25%;
    flex-grow: 0;
    max-width: 25%;
  }
  .flex.order-sm3 {
    order: 3;
  }
  .flex.sm4 {
    flex-basis: 33.33333333333333%;
    flex-grow: 0;
    max-width: 33.33333333333333%;
  }
  .flex.order-sm4 {
    order: 4;
  }
  .flex.sm5 {
    flex-basis: 41.66666666666667%;
    flex-grow: 0;
    max-width: 41.66666666666667%;
  }
  .flex.order-sm5 {
    order: 5;
  }
  .flex.sm6 {
    flex-basis: 50%;
    flex-grow: 0;
    max-width: 50%;
  }
  .flex.order-sm6 {
    order: 6;
  }
  .flex.sm7 {
    flex-basis: 58.333333333333336%;
    flex-grow: 0;
    max-width: 58.333333333333336%;
  }
  .flex.order-sm7 {
    order: 7;
  }
  .flex.sm8 {
    flex-basis: 66.66666666666666%;
    flex-grow: 0;
    max-width: 66.66666666666666%;
  }
  .flex.order-sm8 {
    order: 8;
  }
  .flex.sm9 {
    flex-basis: 75%;
    flex-grow: 0;
    max-width: 75%;
  }
  .flex.order-sm9 {
    order: 9;
  }
  .flex.sm10 {
    flex-basis: 83.33333333333334%;
    flex-grow: 0;
    max-width: 83.33333333333334%;
  }
  .flex.order-sm10 {
    order: 10;
  }
  .flex.sm11 {
    flex-basis: 91.66666666666666%;
    flex-grow: 0;
    max-width: 91.66666666666666%;
  }
  .flex.order-sm11 {
    order: 11;
  }
  .flex.sm12 {
    flex-basis: 100%;
    flex-grow: 0;
    max-width: 100%;
  }
  .flex.order-sm12 {
    order: 12;
  }
  .flex.offset-sm0 {
    margin-left: 0%;
  }
  .flex.offset-sm1 {
    margin-left: 8.333333333333332%;
  }
  .flex.offset-sm2 {
    margin-left: 16.666666666666664%;
  }
  .flex.offset-sm3 {
    margin-left: 25%;
  }
  .flex.offset-sm4 {
    margin-left: 33.33333333333333%;
  }
  .flex.offset-sm5 {
    margin-left: 41.66666666666667%;
  }
  .flex.offset-sm6 {
    margin-left: 50%;
  }
  .flex.offset-sm7 {
    margin-left: 58.333333333333336%;
  }
  .flex.offset-sm8 {
    margin-left: 66.66666666666666%;
  }
  .flex.offset-sm9 {
    margin-left: 75%;
  }
  .flex.offset-sm10 {
    margin-left: 83.33333333333334%;
  }
  .flex.offset-sm11 {
    margin-left: 91.66666666666666%;
  }
  .flex.offset-sm12 {
    margin-left: 100%;
  }
}
@media all and (min-width: 960px) {
  .flex.md1 {
    flex-basis: 8.333333333333332%;
    flex-grow: 0;
    max-width: 8.333333333333332%;
  }
  .flex.order-md1 {
    order: 1;
  }
  .flex.md2 {
    flex-basis: 16.666666666666664%;
    flex-grow: 0;
    max-width: 16.666666666666664%;
  }
  .flex.order-md2 {
    order: 2;
  }
  .flex.md3 {
    flex-basis: 25%;
    flex-grow: 0;
    max-width: 25%;
  }
  .flex.order-md3 {
    order: 3;
  }
  .flex.md4 {
    flex-basis: 33.33333333333333%;
    flex-grow: 0;
    max-width: 33.33333333333333%;
  }
  .flex.order-md4 {
    order: 4;
  }
  .flex.md5 {
    flex-basis: 41.66666666666667%;
    flex-grow: 0;
    max-width: 41.66666666666667%;
  }
  .flex.order-md5 {
    order: 5;
  }
  .flex.md6 {
    flex-basis: 50%;
    flex-grow: 0;
    max-width: 50%;
  }
  .flex.order-md6 {
    order: 6;
  }
  .flex.md7 {
    flex-basis: 58.333333333333336%;
    flex-grow: 0;
    max-width: 58.333333333333336%;
  }
  .flex.order-md7 {
    order: 7;
  }
  .flex.md8 {
    flex-basis: 66.66666666666666%;
    flex-grow: 0;
    max-width: 66.66666666666666%;
  }
  .flex.order-md8 {
    order: 8;
  }
  .flex.md9 {
    flex-basis: 75%;
    flex-grow: 0;
    max-width: 75%;
  }
  .flex.order-md9 {
    order: 9;
  }
  .flex.md10 {
    flex-basis: 83.33333333333334%;
    flex-grow: 0;
    max-width: 83.33333333333334%;
  }
  .flex.order-md10 {
    order: 10;
  }
  .flex.md11 {
    flex-basis: 91.66666666666666%;
    flex-grow: 0;
    max-width: 91.66666666666666%;
  }
  .flex.order-md11 {
    order: 11;
  }
  .flex.md12 {
    flex-basis: 100%;
    flex-grow: 0;
    max-width: 100%;
  }
  .flex.order-md12 {
    order: 12;
  }
  .flex.offset-md0 {
    margin-left: 0%;
  }
  .flex.offset-md1 {
    margin-left: 8.333333333333332%;
  }
  .flex.offset-md2 {
    margin-left: 16.666666666666664%;
  }
  .flex.offset-md3 {
    margin-left: 25%;
  }
  .flex.offset-md4 {
    margin-left: 33.33333333333333%;
  }
  .flex.offset-md5 {
    margin-left: 41.66666666666667%;
  }
  .flex.offset-md6 {
    margin-left: 50%;
  }
  .flex.offset-md7 {
    margin-left: 58.333333333333336%;
  }
  .flex.offset-md8 {
    margin-left: 66.66666666666666%;
  }
  .flex.offset-md9 {
    margin-left: 75%;
  }
  .flex.offset-md10 {
    margin-left: 83.33333333333334%;
  }
  .flex.offset-md11 {
    margin-left: 91.66666666666666%;
  }
  .flex.offset-md12 {
    margin-left: 100%;
  }
}
@media all and (min-width: 1264px) {
  .flex.lg1 {
    flex-basis: 8.333333333333332%;
    flex-grow: 0;
    max-width: 8.333333333333332%;
  }
  .flex.order-lg1 {
    order: 1;
  }
  .flex.lg2 {
    flex-basis: 16.666666666666664%;
    flex-grow: 0;
    max-width: 16.666666666666664%;
  }
  .flex.order-lg2 {
    order: 2;
  }
  .flex.lg3 {
    flex-basis: 25%;
    flex-grow: 0;
    max-width: 25%;
  }
  .flex.order-lg3 {
    order: 3;
  }
  .flex.lg4 {
    flex-basis: 33.33333333333333%;
    flex-grow: 0;
    max-width: 33.33333333333333%;
  }
  .flex.order-lg4 {
    order: 4;
  }
  .flex.lg5 {
    flex-basis: 41.66666666666667%;
    flex-grow: 0;
    max-width: 41.66666666666667%;
  }
  .flex.order-lg5 {
    order: 5;
  }
  .flex.lg6 {
    flex-basis: 50%;
    flex-grow: 0;
    max-width: 50%;
  }
  .flex.order-lg6 {
    order: 6;
  }
  .flex.lg7 {
    flex-basis: 58.333333333333336%;
    flex-grow: 0;
    max-width: 58.333333333333336%;
  }
  .flex.order-lg7 {
    order: 7;
  }
  .flex.lg8 {
    flex-basis: 66.66666666666666%;
    flex-grow: 0;
    max-width: 66.66666666666666%;
  }
  .flex.order-lg8 {
    order: 8;
  }
  .flex.lg9 {
    flex-basis: 75%;
    flex-grow: 0;
    max-width: 75%;
  }
  .flex.order-lg9 {
    order: 9;
  }
  .flex.lg10 {
    flex-basis: 83.33333333333334%;
    flex-grow: 0;
    max-width: 83.33333333333334%;
  }
  .flex.order-lg10 {
    order: 10;
  }
  .flex.lg11 {
    flex-basis: 91.66666666666666%;
    flex-grow: 0;
    max-width: 91.66666666666666%;
  }
  .flex.order-lg11 {
    order: 11;
  }
  .flex.lg12 {
    flex-basis: 100%;
    flex-grow: 0;
    max-width: 100%;
  }
  .flex.order-lg12 {
    order: 12;
  }
  .flex.offset-lg0 {
    margin-left: 0%;
  }
  .flex.offset-lg1 {
    margin-left: 8.333333333333332%;
  }
  .flex.offset-lg2 {
    margin-left: 16.666666666666664%;
  }
  .flex.offset-lg3 {
    margin-left: 25%;
  }
  .flex.offset-lg4 {
    margin-left: 33.33333333333333%;
  }
  .flex.offset-lg5 {
    margin-left: 41.66666666666667%;
  }
  .flex.offset-lg6 {
    margin-left: 50%;
  }
  .flex.offset-lg7 {
    margin-left: 58.333333333333336%;
  }
  .flex.offset-lg8 {
    margin-left: 66.66666666666666%;
  }
  .flex.offset-lg9 {
    margin-left: 75%;
  }
  .flex.offset-lg10 {
    margin-left: 83.33333333333334%;
  }
  .flex.offset-lg11 {
    margin-left: 91.66666666666666%;
  }
  .flex.offset-lg12 {
    margin-left: 100%;
  }
}
@media all and (min-width: 1904px) {
  .flex.xl1 {
    flex-basis: 8.333333333333332%;
    flex-grow: 0;
    max-width: 8.333333333333332%;
  }
  .flex.order-xl1 {
    order: 1;
  }
  .flex.xl2 {
    flex-basis: 16.666666666666664%;
    flex-grow: 0;
    max-width: 16.666666666666664%;
  }
  .flex.order-xl2 {
    order: 2;
  }
  .flex.xl3 {
    flex-basis: 25%;
    flex-grow: 0;
    max-width: 25%;
  }
  .flex.order-xl3 {
    order: 3;
  }
  .flex.xl4 {
    flex-basis: 33.33333333333333%;
    flex-grow: 0;
    max-width: 33.33333333333333%;
  }
  .flex.order-xl4 {
    order: 4;
  }
  .flex.xl5 {
    flex-basis: 41.66666666666667%;
    flex-grow: 0;
    max-width: 41.66666666666667%;
  }
  .flex.order-xl5 {
    order: 5;
  }
  .flex.xl6 {
    flex-basis: 50%;
    flex-grow: 0;
    max-width: 50%;
  }
  .flex.order-xl6 {
    order: 6;
  }
  .flex.xl7 {
    flex-basis: 58.333333333333336%;
    flex-grow: 0;
    max-width: 58.333333333333336%;
  }
  .flex.order-xl7 {
    order: 7;
  }
  .flex.xl8 {
    flex-basis: 66.66666666666666%;
    flex-grow: 0;
    max-width: 66.66666666666666%;
  }
  .flex.order-xl8 {
    order: 8;
  }
  .flex.xl9 {
    flex-basis: 75%;
    flex-grow: 0;
    max-width: 75%;
  }
  .flex.order-xl9 {
    order: 9;
  }
  .flex.xl10 {
    flex-basis: 83.33333333333334%;
    flex-grow: 0;
    max-width: 83.33333333333334%;
  }
  .flex.order-xl10 {
    order: 10;
  }
  .flex.xl11 {
    flex-basis: 91.66666666666666%;
    flex-grow: 0;
    max-width: 91.66666666666666%;
  }
  .flex.order-xl11 {
    order: 11;
  }
  .flex.xl12 {
    flex-basis: 100%;
    flex-grow: 0;
    max-width: 100%;
  }
  .flex.order-xl12 {
    order: 12;
  }
  .flex.offset-xl0 {
    margin-left: 0%;
  }
  .flex.offset-xl1 {
    margin-left: 8.333333333333332%;
  }
  .flex.offset-xl2 {
    margin-left: 16.666666666666664%;
  }
  .flex.offset-xl3 {
    margin-left: 25%;
  }
  .flex.offset-xl4 {
    margin-left: 33.33333333333333%;
  }
  .flex.offset-xl5 {
    margin-left: 41.66666666666667%;
  }
  .flex.offset-xl6 {
    margin-left: 50%;
  }
  .flex.offset-xl7 {
    margin-left: 58.333333333333336%;
  }
  .flex.offset-xl8 {
    margin-left: 66.66666666666666%;
  }
  .flex.offset-xl9 {
    margin-left: 75%;
  }
  .flex.offset-xl10 {
    margin-left: 83.33333333333334%;
  }
  .flex.offset-xl11 {
    margin-left: 91.66666666666666%;
  }
  .flex.offset-xl12 {
    margin-left: 100%;
  }
}
.flex,
.child-flex > * {
  flex: 1 1 auto;
  max-width: 100%;
}
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}
.align-center {
  align-items: center;
}
.align-baseline {
  align-items: baseline;
}
.align-self-start {
  align-self: flex-start;
}
.align-self-end {
  align-self: flex-end;
}
.align-self-center {
  align-self: center;
}
.align-self-baseline {
  align-self: baseline;
}
.align-content-start {
  align-content: flex-start;
}
.align-content-end {
  align-content: flex-end;
}
.align-content-center {
  align-content: center;
}
.align-content-space-between {
  align-content: space-between;
}
.align-content-space-around {
  align-content: space-around;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-space-around {
  justify-content: space-around;
}
.justify-space-between {
  justify-content: space-between;
}
.justify-self-start {
  justify-self: flex-start;
}
.justify-self-end {
  justify-self: flex-end;
}
.justify-self-center {
  justify-self: center;
}
.justify-self-baseline {
  justify-self: baseline;
}
.spacer {
  flex-grow: 1 !important;
}
.grow {
  flex-grow: 1 !important;
  flex-shrink: 0 !important;
}
.shrink {
  flex-grow: 0 !important;
  flex-shrink: 1 !important;
}
.scroll-y {
  overflow-y: auto;
}
.fill-height {
  height: 100%;
}
.hide-overflow {
  overflow: hidden !important;
}
.show-overflow {
  overflow: visible !important;
}
.no-wrap {
  white-space: nowrap;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
.d-flex > *,
.d-inline-flex > * {
  flex: 1 1 auto !important;
}
.d-block {
  display: block !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-inline {
  display: inline !important;
}
.d-none {
  display: none !important;
}
/*# sourceMappingURL=node_modules/vuetify/src/stylus/components/_grid.css.map */