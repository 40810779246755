.theme--light.v-textarea.v-text-field--solo-inverted.v-text-field--solo.v-input--is-focused textarea {
  color: #fff;
}
.theme--dark.v-textarea.v-text-field--solo-inverted.v-text-field--solo.v-input--is-focused textarea {
  color: rgba(0,0,0,0.87);
}
.application--is-rtl .v-textarea.v-text-field--enclosed .v-text-field__slot {
  margin-right: 0;
  margin-left: -12px;
}
.application--is-rtl .v-textarea.v-text-field--enclosed .v-text-field__slot textarea {
  padding-right: 0;
  padding-left: 12px;
}
.v-textarea textarea {
  flex: 1 1 auto;
  line-height: 18px;
  max-width: 100%;
  min-height: 32px;
  outline: none;
  padding: 7px 0 8px;
  width: 100%;
}
.v-textarea .v-text-field__prefix {
  padding-top: 4px;
  align-self: start;
}
.v-textarea.v-text-field--full-width .v-text-field__slot textarea,
.v-textarea.v-text-field--full-width.v-text-field--single-line .v-text-field__slot textarea {
  margin-top: 0;
}
.v-textarea.v-text-field--full-width .v-text-field__details,
.v-textarea.v-text-field--full-width.v-text-field--single-line .v-text-field__details {
  bottom: 4px;
}
.v-textarea.v-text-field--enclosed .v-text-field__slot {
  margin-right: -12px;
}
.v-textarea.v-text-field--enclosed .v-text-field__slot textarea {
  padding-right: 12px;
}
.v-textarea.v-text-field--box .v-text-field__prefix,
.v-textarea.v-text-field--enclosed .v-text-field__prefix,
.v-textarea.v-text-field--box textarea,
.v-textarea.v-text-field--enclosed textarea {
  margin-top: 24px;
}
.v-textarea.v-text-field--box.v-text-field--single-line .v-text-field__prefix,
.v-textarea.v-text-field--enclosed.v-text-field--single-line .v-text-field__prefix,
.v-textarea.v-text-field--box.v-text-field--single-line textarea,
.v-textarea.v-text-field--enclosed.v-text-field--single-line textarea {
  margin-top: 12px;
}
.v-textarea.v-text-field--box.v-text-field--single-line .v-label,
.v-textarea.v-text-field--enclosed.v-text-field--single-line .v-label {
  top: 18px;
}
.v-textarea.v-text-field--box.v-text-field--single-line.v-text-field--outline .v-input__control,
.v-textarea.v-text-field--enclosed.v-text-field--single-line.v-text-field--outline .v-input__control {
  padding-top: 0;
}
.v-textarea.v-text-field--solo {
  align-items: flex-start;
}
.v-textarea.v-text-field--solo .v-input__prepend-inner,
.v-textarea.v-text-field--solo .v-input__prepend-outer,
.v-textarea.v-text-field--solo .v-input__append-inner,
.v-textarea.v-text-field--solo .v-input__append-outer {
  align-self: flex-start;
  margin-top: 16px;
}
.v-textarea--auto-grow textarea {
  overflow: hidden;
}
.v-textarea--no-resize textarea {
  resize: none;
}
/*# sourceMappingURL=node_modules/vuetify/src/stylus/components/_textarea.css.map */