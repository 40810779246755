/* Themes */
.theme--light.v-card {
  background-color: #fff;
  border-color: #fff;
  color: rgba(0,0,0,0.87);
}
.theme--dark.v-card {
  background-color: #424242;
  border-color: #424242;
  color: #fff;
}
.v-card {
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  text-decoration: none;
}
.v-card > *:first-child:not(.v-btn):not(.v-chip) {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.v-card > *:last-child:not(.v-btn):not(.v-chip) {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
.v-card--flat {
  box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);
}
.v-card--hover {
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: box-shadow;
}
.v-card--hover:hover {
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
}
.v-card__title {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
}
.v-card__title--primary {
  padding-top: 24px;
}
.v-card__text {
  padding: 16px;
  width: 100%;
}
.v-card__actions {
  align-items: center;
  display: flex;
  padding: 8px;
}
.v-card__actions > *,
.v-card__actions .v-btn {
  margin: 0;
}
.v-card__actions .v-btn + .v-btn {
  margin-left: 8px;
}
/*# sourceMappingURL=node_modules/vuetify/src/stylus/components/_cards.css.map */