.v-form > .container {
  padding: 16px;
}
.v-form > .container > .layout > .flex {
  padding: 8px;
}
.v-form > .container > .layout:only-child {
  margin: -8px;
}
.v-form > .container > .layout:not(:only-child) {
  margin: auto -8px;
}
/*# sourceMappingURL=node_modules/vuetify/src/stylus/components/_forms.css.map */