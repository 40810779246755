.v-ripple__container {
  color: inherit;
  border-radius: inherit;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  z-index: 0;
  pointer-events: none;
  contain: strict;
}
.v-ripple__animation {
  color: inherit;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background: currentColor;
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
  will-change: transform, opacity;
}
.v-ripple__animation--enter {
  transition: none;
}
.v-ripple__animation--in {
  transition: transform 0.25s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.1s cubic-bezier(0.4, 0, 0.2, 1);
}
.v-ripple__animation--out {
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}
.theme--light.v-btn {
  color: rgba(0,0,0,0.87);
}
.theme--light.v-btn.v-btn--disabled {
  color: rgba(0,0,0,0.26) !important;
}
.theme--light.v-btn.v-btn--disabled .v-icon,
.theme--light.v-btn.v-btn--disabled .v-btn__loading {
  color: rgba(0,0,0,0.26) !important;
}
.theme--light.v-btn.v-btn--disabled:not(.v-btn--icon):not(.v-btn--flat):not(.v-btn--outline) {
  background-color: rgba(0,0,0,0.12) !important;
}
.theme--light.v-btn:not(.v-btn--icon):not(.v-btn--flat) {
  background-color: #f5f5f5;
}
.theme--dark.v-btn {
  color: #fff;
}
.theme--dark.v-btn.v-btn--disabled {
  color: rgba(255,255,255,0.3) !important;
}
.theme--dark.v-btn.v-btn--disabled .v-icon,
.theme--dark.v-btn.v-btn--disabled .v-btn__loading {
  color: rgba(255,255,255,0.3) !important;
}
.theme--dark.v-btn.v-btn--disabled:not(.v-btn--icon):not(.v-btn--flat):not(.v-btn--outline) {
  background-color: rgba(255,255,255,0.12) !important;
}
.theme--dark.v-btn:not(.v-btn--icon):not(.v-btn--flat) {
  background-color: #212121;
}
/** Base Spec */
.v-btn {
  align-items: center;
  border-radius: 2px;
  display: inline-flex;
  height: 36px;
  flex: 0 0 auto;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  margin: 6px 8px;
  min-width: 88px;
  outline: 0;
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
  position: relative;
  vertical-align: middle;
  user-select: none;
}
/** Psuedo */
.v-btn:before {
  border-radius: inherit;
  color: inherit;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  opacity: 0.12;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}
/** Content */
.v-btn {
  padding: 0 16px;
}
.v-btn--active,
.v-btn:hover,
.v-btn:focus {
  position: relative;
}
.v-btn--active:before,
.v-btn:hover:before,
.v-btn:focus:before {
  background-color: currentColor;
}
@media (hover: none) {
  .v-btn:hover::before {
    background-color: transparent;
  }
}
.v-btn__content {
  align-items: center;
  border-radius: inherit;
  color: inherit;
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  white-space: nowrap;
  width: inherit;
}
/** Sizes */
.v-btn--small {
  font-size: 13px;
  height: 28px;
  padding: 0 8px;
}
.v-btn--large {
  font-size: 15px;
  height: 44px;
  padding: 0 32px;
}
/** Icons */
.v-btn .v-btn__content .v-icon {
  color: inherit;
}
/** Types */
.v-btn:not(.v-btn--depressed):not(.v-btn--flat) {
  will-change: box-shadow;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}
.v-btn:not(.v-btn--depressed):not(.v-btn--flat):active {
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
}
.v-btn--icon {
  background: transparent;
  box-shadow: none !important;
  border-radius: 50%;
  justify-content: center;
  min-width: 0;
  width: 36px;
}
.v-btn--icon.v-btn--small {
  width: 28px;
}
.v-btn--icon.v-btn--large {
  width: 44px;
}
.v-btn--icon:before {
  border-radius: 50%;
}
.v-btn--floating {
  border-radius: 50%;
  min-width: 0;
  height: 56px;
  width: 56px;
  padding: 0;
}
.v-btn--floating.v-btn--fixed,
.v-btn--floating.v-btn--absolute {
  z-index: 4;
}
.v-btn--floating:not(.v-btn--depressed):not(.v-btn--flat) {
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
}
.v-btn--floating:not(.v-btn--depressed):not(.v-btn--flat):active {
  box-shadow: 0px 7px 8px -4px rgba(0,0,0,0.2), 0px 12px 17px 2px rgba(0,0,0,0.14), 0px 5px 22px 4px rgba(0,0,0,0.12);
}
.v-btn--floating .v-btn__content {
  flex: 1 1 auto;
  margin: 0;
  height: 100%;
}
.v-btn--floating:after {
  border-radius: 50%;
}
.v-btn--floating .v-btn__content > :not(:only-child) {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-btn--floating .v-btn__content > :not(:only-child):first-child {
  opacity: 1;
}
.v-btn--floating .v-btn__content > :not(:only-child):last-child {
  opacity: 0;
  transform: rotate(-45deg);
}
.v-btn--floating .v-btn__content > :not(:only-child):last-child,
.v-btn--floating .v-btn__content > :not(:only-child):first-child {
  -webkit-backface-visibility: hidden;
  position: absolute;
  left: 0;
  top: 0;
}
.v-btn--floating.v-btn--active .v-btn__content > :not(:only-child):first-child {
  opacity: 0;
  transform: rotate(45deg);
}
.v-btn--floating.v-btn--active .v-btn__content > :not(:only-child):last-child {
  opacity: 1;
  transform: rotate(0);
}
.v-btn--floating .v-icon {
  height: inherit;
  width: inherit;
}
.v-btn--floating.v-btn--small {
  height: 40px;
  width: 40px;
}
.v-btn--floating.v-btn--small .v-icon {
  font-size: 18px;
}
.v-btn--floating.v-btn--large {
  height: 72px;
  width: 72px;
}
.v-btn--floating.v-btn--large .v-icon {
  font-size: 30px;
}
.v-btn--reverse .v-btn__content {
  flex-direction: row-reverse;
}
.v-btn--reverse.v-btn--column .v-btn__content {
  flex-direction: column-reverse;
}
.v-btn--fixed,
.v-btn--absolute {
  margin: 0;
}
.v-btn.v-btn--absolute {
  position: absolute;
}
.v-btn.v-btn--fixed {
  position: fixed;
}
.v-btn--top:not(.v-btn--absolute) {
  top: 16px;
}
.v-btn--top.v-btn--absolute {
  top: -28px;
}
.v-btn--top.v-btn--absolute.v-btn--small {
  top: -20px;
}
.v-btn--top.v-btn--absolute.v-btn--large {
  top: -36px;
}
.v-btn--bottom:not(.v-btn--absolute) {
  bottom: 16px;
}
.v-btn--bottom.v-btn--absolute {
  bottom: -28px;
}
.v-btn--bottom.v-btn--absolute.v-btn--small {
  bottom: -20px;
}
.v-btn--bottom.v-btn--absolute.v-btn--large {
  bottom: -36px;
}
.v-btn--left {
  left: 16px;
}
.v-btn--right {
  right: 16px;
}
/** Disabled */
.v-btn.v-btn--disabled {
  box-shadow: none !important;
  pointer-events: none;
}
.v-btn:not(.v-btn--disabled):not(.v-btn--floating):not(.v-btn--icon) .v-btn__content .v-icon {
  transition: none;
}
.v-btn--icon {
  padding: 0;
}
/** Loader */
.v-btn--loader {
  pointer-events: none;
}
.v-btn--loader .v-btn__content {
  opacity: 0;
}
.v-btn__loading {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.v-btn__loading .v-icon--left {
  margin-right: 1rem;
  line-height: inherit;
}
.v-btn__loading .v-icon--right {
  margin-left: 1rem;
  line-height: inherit;
}
/** Custom Buttons */
.v-btn.v-btn--outline {
  border: 1px solid currentColor;
  background: transparent !important;
  box-shadow: none;
}
.v-btn.v-btn--outline:hover {
  box-shadow: none;
}
.v-btn--block {
  display: flex;
  flex: 1;
  margin: 6px 0;
  width: 100%;
}
.v-btn--round {
  border-radius: 28px;
}
.v-btn--round:after {
  border-radius: 28px;
}
/** Themes */
.v-btn:not(.v-btn--outline).primary,
.v-btn:not(.v-btn--outline).secondary,
.v-btn:not(.v-btn--outline).accent,
.v-btn:not(.v-btn--outline).success,
.v-btn:not(.v-btn--outline).error,
.v-btn:not(.v-btn--outline).warning,
.v-btn:not(.v-btn--outline).info {
  color: #fff;
}
/*# sourceMappingURL=node_modules/vuetify/src/stylus/components/_buttons.css.map */