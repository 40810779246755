.application {
  display: flex;
}
.application a {
  cursor: pointer;
}
.application--is-rtl {
  direction: rtl;
}
.application--wrap {
  flex: 1 1 auto;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  position: relative;
}
.theme--light.application {
  background: #fafafa;
  color: rgba(0,0,0,0.87);
}
.theme--light.application .text--primary {
  color: rgba(0,0,0,0.87) !important;
}
.theme--light.application .text--secondary {
  color: rgba(0,0,0,0.54) !important;
}
.theme--light.application .text--disabled {
  color: rgba(0,0,0,0.38) !important;
}
.theme--dark.application {
  background: #303030;
  color: #fff;
}
.theme--dark.application .text--primary {
  color: #fff !important;
}
.theme--dark.application .text--secondary {
  color: rgba(255,255,255,0.7) !important;
}
.theme--dark.application .text--disabled {
  color: rgba(255,255,255,0.5) !important;
}
@-moz-document url-prefix() {
@media print {
    .application {
      display: block;
    }
    .application--wrap {
      display: block;
    }
}
}
/*# sourceMappingURL=node_modules/vuetify/src/stylus/components/_app.css.map */