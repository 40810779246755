/* Theme */
.theme--light.v-input:not(.v-input--is-disabled) input,
.theme--light.v-input:not(.v-input--is-disabled) textarea {
  color: rgba(0,0,0,0.87);
}
.theme--light.v-input input::placeholder,
.theme--light.v-input textarea::placeholder {
  color: rgba(0,0,0,0.38);
}
.theme--light.v-input--is-disabled .v-label,
.theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: rgba(0,0,0,0.38);
}
.theme--dark.v-input:not(.v-input--is-disabled) input,
.theme--dark.v-input:not(.v-input--is-disabled) textarea {
  color: #fff;
}
.theme--dark.v-input input::placeholder,
.theme--dark.v-input textarea::placeholder {
  color: rgba(255,255,255,0.5);
}
.theme--dark.v-input--is-disabled .v-label,
.theme--dark.v-input--is-disabled input,
.theme--dark.v-input--is-disabled textarea {
  color: rgba(255,255,255,0.5);
}
.v-input {
  align-items: flex-start;
  display: flex;
  flex: 1 1 auto;
  font-size: 16px;
  text-align: left;
}
.v-input .v-progress-linear {
  top: calc(100% - 1px);
  left: 0;
  margin: 0;
  position: absolute;
}
.v-input input {
  max-height: 32px;
}
.v-input input:invalid,
.v-input textarea:invalid {
  box-shadow: none;
}
.v-input input:focus,
.v-input textarea:focus,
.v-input input:active,
.v-input textarea:active {
  outline: none;
}
.v-input .v-label {
  height: 20px;
  line-height: 20px;
}
.v-input__append-outer,
.v-input__prepend-outer {
  display: inline-flex;
  margin-bottom: 4px;
  margin-top: 4px;
  line-height: 1;
}
.v-input__append-outer .v-icon,
.v-input__prepend-outer .v-icon {
  user-select: none;
}
.v-input__append-outer {
  margin-left: 9px;
}
.v-input__prepend-outer {
  margin-right: 9px;
}
.v-input__control {
  display: flex;
  flex-direction: column;
  height: auto;
  flex-grow: 1;
  flex-wrap: wrap;
  width: 100%;
}
.v-input__icon {
  align-items: center;
  display: inline-flex;
  height: 24px;
  flex: 1 0 auto;
  justify-content: center;
  min-width: 24px;
  width: 24px;
}
.v-input__icon--clear {
  border-radius: 50%;
}
.v-input__slot {
  align-items: center;
  color: inherit;
  display: flex;
  margin-bottom: 8px;
  min-height: inherit;
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}
.v-input--is-disabled:not(.v-input--is-readonly) {
  pointer-events: none;
}
.v-input--is-loading > .v-input__control > .v-input__slot:before,
.v-input--is-loading > .v-input__control > .v-input__slot:after {
  display: none;
}
.v-input--hide-details > .v-input__control > .v-input__slot {
  margin-bottom: 0;
}
.v-input--has-state.error--text .v-label {
  animation: shake 0.6s cubic-bezier(0.25, 0.8, 0.5, 1);
}
/*# sourceMappingURL=node_modules/vuetify/src/stylus/components/_inputs.css.map */