.theme--light.v-chip {
  background: #e0e0e0;
  color: rgba(0,0,0,0.87);
}
.theme--light.v-chip--disabled {
  color: rgba(0,0,0,0.38);
}
.theme--dark.v-chip {
  background: #555;
  color: #fff;
}
.theme--dark.v-chip--disabled {
  color: rgba(255,255,255,0.5);
}
.application--is-rtl .v-chip__close {
  margin: 0 8px 0 2px;
}
.application--is-rtl .v-chip--removable .v-chip__content {
  padding: 0 12px 0 4px;
}
.application--is-rtl .v-chip--select-multi {
  margin: 4px 0 4px 4px;
}
.application--is-rtl .v-chip .v-avatar {
  margin-right: -12px;
  margin-left: 8px;
}
.application--is-rtl .v-chip .v-icon--right {
  margin-right: 12px;
  margin-left: -8px;
}
.application--is-rtl .v-chip .v-icon--left {
  margin-right: -8px;
  margin-left: 12px;
}
.v-chip {
  align-items: center;
  border-radius: 28px;
  display: inline-flex;
  font-size: 13px;
  margin: 4px;
  outline: none;
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  vertical-align: middle;
}
.v-chip .v-chip__content {
  align-items: center;
  border-radius: 28px;
  cursor: default;
  display: inline-flex;
  height: 32px;
  justify-content: space-between;
  padding: 0 12px;
  vertical-align: middle;
  white-space: nowrap;
  z-index: 1;
}
.v-chip--removable .v-chip__content {
  padding: 0 4px 0 12px;
}
.v-chip .v-avatar {
  height: 32px !important;
  margin-left: -12px;
  margin-right: 8px;
  min-width: 32px;
  width: 32px !important;
}
.v-chip .v-avatar img {
  height: 100%;
  width: 100%;
}
.v-chip:focus:not(.v-chip--disabled),
.v-chip--active,
.v-chip--selected {
  border-color: rgba(0,0,0,0.13);
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}
.v-chip:focus:not(.v-chip--disabled):after,
.v-chip--active:after,
.v-chip--selected:after {
  background: currentColor;
  border-radius: inherit;
  content: '';
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: inherit;
  width: 100%;
  pointer-events: none;
  opacity: 0.13;
}
.v-chip--label {
  border-radius: 2px;
}
.v-chip--label .v-chip__content {
  border-radius: 2px;
}
.v-chip.v-chip.v-chip--outline {
  background: transparent !important;
  border: 1px solid currentColor;
  color: #9e9e9e;
  height: 32px;
}
.v-chip.v-chip.v-chip--outline .v-avatar {
  margin-left: -13px;
}
.v-chip--small {
  height: 24px !important;
}
.v-chip--small .v-avatar {
  height: 24px !important;
  min-width: 24px;
  width: 24px !important;
}
.v-chip--small .v-icon {
  font-size: 20px;
}
.v-chip__close {
  align-items: center;
  color: inherit;
  display: flex;
  font-size: 20px;
  margin: 0 2px 0 8px;
  text-decoration: none;
  user-select: none;
}
.v-chip__close > .v-icon {
  color: inherit !important;
  font-size: 20px;
  cursor: pointer;
  opacity: 0.5;
}
.v-chip__close > .v-icon:hover {
  opacity: 1;
}
.v-chip--disabled .v-chip__close {
  pointer-events: none;
}
.v-chip--select-multi {
  margin: 4px 4px 4px 0;
}
.v-chip .v-icon {
  color: inherit;
}
.v-chip .v-icon--right {
  margin-left: 12px;
  margin-right: -8px;
}
.v-chip .v-icon--left {
  margin-left: -8px;
  margin-right: 12px;
}
/*# sourceMappingURL=node_modules/vuetify/src/stylus/components/_chips.css.map */