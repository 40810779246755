.v-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  z-index: 5;
}
.v-overlay--absolute {
  position: absolute;
}
.v-overlay:before {
  background-color: #212121;
  bottom: 0;
  content: '';
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: inherit;
  transition-delay: 150ms;
  width: 100%;
}
.v-overlay--active {
  pointer-events: auto;
  touch-action: none;
}
.v-overlay--active:before {
  opacity: 0.46;
}
/*# sourceMappingURL=node_modules/vuetify/src/stylus/components/_overlay.css.map */