.theme--light.v-label {
  color: rgba(0,0,0,0.54);
}
.theme--light.v-label--is-disabled {
  color: rgba(0,0,0,0.38);
}
.theme--dark.v-label {
  color: rgba(255,255,255,0.7);
}
.theme--dark.v-label--is-disabled {
  color: rgba(255,255,255,0.5);
}
.v-label {
  font-size: 16px;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
/*# sourceMappingURL=node_modules/vuetify/src/stylus/components/_labels.css.map */