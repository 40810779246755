.theme--light.v-radio--is-disabled label {
  color: rgba(0,0,0,0.38);
}
.theme--light.v-radio--is-disabled .v-icon {
  color: rgba(0,0,0,0.26) !important;
}
.theme--dark.v-radio--is-disabled label {
  color: rgba(255,255,255,0.5);
}
.theme--dark.v-radio--is-disabled .v-icon {
  color: rgba(255,255,255,0.3) !important;
}
.v-radio {
  align-items: center;
  display: flex;
  height: auto;
  margin-right: 16px;
  outline: none;
}
.v-radio--is-disabled {
  pointer-events: none;
}
/*# sourceMappingURL=node_modules/vuetify/src/stylus/components/_radios.css.map */