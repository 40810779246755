.v-tooltip__content {
  background: #616161;
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
  display: inline-block;
  padding: 5px 8px;
  position: absolute;
  text-transform: initial;
  width: auto;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}
.v-tooltip__content[class*="-active"] {
  transition: 0.15s cubic-bezier(0.25, 0.8, 0.5, 1);
  pointer-events: none;
}
.v-tooltip__content--fixed {
  position: fixed;
}
@media only screen and (max-width: 959px) {
  .v-tooltip .v-tooltip__content {
    padding: 10px 16px;
  }
}
/*# sourceMappingURL=node_modules/vuetify/src/stylus/components/_tooltips.css.map */